import React from 'react';
import {Layout, LayoutProps, LogoHeaderEnum} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {useNavigate} from 'react-router';

import AppNavigator from 'components/common/AppNavigator';
import {useLayoutContext} from 'layouts/LayoutContext';
import routeUrls from 'constants/routeUrls';

const AppWrapper: React.FC<Omit<LayoutProps, 'onClickByLogo'>> = (props) => {
	const {children} = props;

	const navigate = useNavigate();

	const {toggleDrawer} = useLayoutContext();

	const linksToAdminPanels = [
		{
			label: 'Shopstat',
			link: routeUrls.ADMINS.SHOPSTAT,
		},
		{
			label: 'Ответо',
			link: routeUrls.ADMINS.OTVETO_RU,
		},
	];

	return (
		<Layout
			logo={LogoHeaderEnum.BOT_LIMITS}
			onClickByLogo={() => navigate(routeUrls.APP_DASHBOARD)}
			openMenuMobile={() => toggleDrawer(true)}
			linksToAdminPanels={linksToAdminPanels}
			menu={<AppNavigator />}
			{...props}
		>
			{children}
		</Layout>
	);
};

export default AppWrapper;
