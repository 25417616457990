import React, {useContext, useRef, useState} from 'react';
import {Grid, Popover} from '@mui/material';
import Button from '@mui/material/Button';

import css from './Confirm.pcss';

interface IConfirm {
	message: React.ReactNode;
	el: HTMLElement;
	btnProps?: {
		reversColor: boolean;
	};
}

type UseModalShowReturnType = {
	show: boolean;
	setShow: (value: boolean) => void;
	onHide: () => void;
};

const useModalShow = (): UseModalShowReturnType => {
	const [show, setShow] = useState(false);

	const handleOnHide = () => {
		setShow(false);
	};

	return {
		show,
		setShow,
		onHide: handleOnHide,
	};
};

type ModalContextType = {
	showConfirmation: ({message, btnProps}: IConfirm) => Promise<boolean>;
};

type ConfirmationModalContextProviderProps = {
	children: React.ReactNode;
};

const ConfirmationModalContext = React.createContext<ModalContextType>({} as ModalContextType);

const ConfirmationModalContextProvider: React.FC<ConfirmationModalContextProviderProps> = (
	props,
) => {
	const {setShow, show, onHide} = useModalShow();
	const [content, setContent] = useState<IConfirm | null>();
	const resolver = useRef<Function>();

	const handleShow = (props: IConfirm): Promise<boolean> => {
		setContent(props);
		setShow(true);
		return new Promise(function (resolve) {
			resolver.current = resolve;
		});
	};

	const modalContext: ModalContextType = {
		showConfirmation: handleShow,
	};

	const handleOk = () => {
		resolver.current && resolver.current(true);
		onHide();
	};

	const handleCancel = () => {
		resolver.current && resolver.current(false);
		onHide();
	};

	return (
		<ConfirmationModalContext.Provider value={modalContext}>
			{props.children}

			{content && (
				<Popover
					anchorEl={content.el}
					open={show}
					onClose={onHide}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					elevation={0}
					classes={{paper: css.paper}}
					BackdropProps={{style: {backgroundColor: 'rgba(0,0,0,0)'}}}
				>
					<div className={css.content}>
						<div className={css.message}>{content.message}</div>

						<Grid container spacing={2} justifyContent={'center'}>
							<Grid item>
								<Button
									size={'small'}
									onClick={handleOk}
									className={css.btn}
									color={'success'}
								>
									Да
								</Button>
							</Grid>

							<Grid item>
								<Button onClick={handleCancel} className={css.btn}>
									Нет
								</Button>
							</Grid>
						</Grid>
					</div>
				</Popover>
			)}
		</ConfirmationModalContext.Provider>
	);
};

const useConfirmationModalContext = (): ModalContextType => useContext(ConfirmationModalContext);

export {useModalShow, useConfirmationModalContext};

export default ConfirmationModalContextProvider;
