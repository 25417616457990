import React, {useMemo} from 'react';
import {SwipeableDrawer, Theme, useMediaQuery} from '@mui/material';
import {useNavigate} from 'react-router';
import {MenuItemsProps, Sidebar} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import {useLayoutContext} from 'layouts/LayoutContext';
import {logoutMutation} from 'api/users';
import routeUrls from 'constants/routeUrls';
import {ReactComponent as IconList} from './icons/IconList.svg';
import {ReactComponent as IconAdd} from './icons/IconAdd.svg';
import {ReactComponent as IconIncome} from './icons/IconIncome.svg';
import {ReactComponent as IconUTM} from './icons/IconUTM.svg';
import css from './AppNavigator.pcss';

const AppNavigator: React.FC = () => {
	const navigate = useNavigate();

	const menuItems: MenuItemsProps[] = useMemo(
		() => [
			{
				head: 'Пользователи телеграм',
				items: [
					{
						title: 'Список пользователей',
						link: routeUrls.APP_TELEGRAM_USERS_LIST,
						Icon: IconList,
					},
					{
						title: 'Платежи',
						link: routeUrls.APP_PAY_LIST,
						Icon: IconIncome,
					},
				],
			},
			{
				head: 'Управление',
				items: [
					{
						title: 'Создать новую',
						link: routeUrls.APP_CREATE_BROADCAST,
						Icon: IconAdd,
					},
					{
						title: 'Список рассылок',
						link: routeUrls.APP_BROADCAST_LIST,
						Icon: IconList,
					},
					{
						title: 'Utm-метки',
						link: routeUrls.APP_UTM_SOURCE,
						Icon: IconUTM,
					},
				],
			},
		],
		[],
	);

	const subMenu = useMemo(
		() => [
			{
				title: 'Бот лимитов',
				link: routeUrls.BOT_LIMITS_TELEGRAM,
			},
			{
				title: 'Выйти',
				onClick: () => logoutMutation().then(() => navigate(routeUrls.AUTH_LOGIN)),
			},
		],
		[navigate],
	);

	const {drawerIsOpen, toggleDrawer} = useLayoutContext();
	const isFromLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

	const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

	return isFromLg ? (
		<SwipeableDrawer
			open={drawerIsOpen}
			onClose={() => toggleDrawer(false)}
			onOpen={() => toggleDrawer(true)}
			onClick={() => toggleDrawer(false)}
			disableBackdropTransition={!iOS}
			disableDiscovery={iOS}
			PaperProps={{className: css.paper}}
		>
			<Sidebar itemsMenu={menuItems} subMenu={subMenu} />
		</SwipeableDrawer>
	) : (
		<div className={css.paper}>
			<Sidebar itemsMenu={menuItems} subMenu={subMenu} />
		</div>
	);
};

export default AppNavigator;
