import React from 'react';
import {IModal, Modal} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {useFormik} from 'formik';
import {CardContent, DialogContent} from '@mui/material';
import {useSnackbar} from 'notistack';
import {number, object, string} from 'yup';

import {useAddUTM} from 'api/utm_source';
import FormUtmSource from 'components/utm_source/FormUtmSource';

interface ICreateUtmSourceModal {
	refetch: () => void;
}

const CreateUtmSourceModal: React.FC<ICreateUtmSourceModal & IModal> = (props) => {
	const {refetch} = props;

	const [createUTM, {loading}] = useAddUTM();

	const {enqueueSnackbar} = useSnackbar();

	const formik = useFormik({
		initialValues: {
			utmCost: undefined,
			utmName: undefined,
			utmSource: '',
		},
		onSubmit: ({utmCost, utmName, utmSource}) =>
			createUTM({
				variables: {
					input: {
						utmCost,
						utmName,
						utmSource,
					},
				},
			})
				.then(() => {
					enqueueSnackbar('Utm - метка создана', {variant: 'success'});
					props.onClose && props.onClose();
					refetch();
				})
				.catch((error) => {
					enqueueSnackbar(
						<>
							Ошибка: <br />${error.message}
						</>,
						{variant: 'error'},
					);
				}),
		validationSchema: object().shape({
			utmSource: string().required('Укажите название utm - метки'),
			utmCost: number().required('Укажите стоимость').min(0, 'Укажите стоимость'),
		}),
	});

	return (
		<Modal {...props} title={'Создание Utm - метки'}>
			<DialogContent>
				<CardContent>
					<FormUtmSource formik={formik} loading={loading} />
				</CardContent>
			</DialogContent>
		</Modal>
	);
};

export default CreateUtmSourceModal;
