import React from 'react';

const StatusSupplyCellFormat = ({value}: {value: string}) => {
	switch (value) {
		case 'ACTIVE':
			return <>Активна</>;
		case 'TIMEOUT':
			return <>Срок для поиска истёк</>;
		case 'WBERROR':
			return <>Ошибка</>;

		//resultStatus
		case 'FOUND':
			return <>Поставка найдена</>;
		case 'EXPIRED':
			return <>Срок для поиска истёк или ошибка</>;

		default:
			return <></>;
	}
};

export default StatusSupplyCellFormat;
