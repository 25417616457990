import {ApolloClient, InMemoryCache, ApolloLink, concat} from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';

import appConsts from 'constants/appConsts';
import {getToken} from 'utils/auth';

const uploadLink = createUploadLink({
	uri: appConsts.GRAPHQL_URI,
});

const authLink = new ApolloLink((operation, forward) => {
	operation.setContext(({headers}: any) => {
		const token = getToken();

		return {
			headers: {
				...headers,
				authorization: token || '',
			},
		};
	});

	return forward(operation);
});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
	link: concat(authLink, uploadLink),
	cache,
});

export default apolloClient;
