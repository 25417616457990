import React, {useEffect, useMemo, useState} from 'react';
import {ITableColumn} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/components/common/ui/table/Table';
import {format} from 'date-fns';
import {
	Button,
	ContentBlock,
	ITableRow,
	PiecesCellFormat,
	RubleCellFormat,
	SearchField,
	TableAdaptive,
	Tooltip,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {Grid, IconButton, Pagination, Tooltip as TooltipMui} from '@mui/material';
import {mdiSquareEditOutline} from '@mdi/js';
import Icon from '@mdi/react';

import EditUtmSourceModal from 'components/utm_source/EditUtmSourceModal';
import {useUtmStatsQuery} from 'api/utm_source';
import CreateUtmSourceModal from 'components/utm_source/CreateUtmSourceModal';
import {TableOrder} from 'utils/table';
import {OrderDirection, UtmSortingBy} from '../../gqlApp/graphql';
import theme from '../../muiTheme';

const UtmSourceTable = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [getUtmSource, {data, loading, error, refetch}] = useUtmStatsQuery();
	const [searchValue, setSearchValue] = useState('');

	const [open, setOpen] = useState<boolean>(false);

	const [editUtmSource, setEditUtmSource] = useState<number>();

	const [sortBy, setSortBy] = useState<string>(UtmSortingBy.CreatedAt);
	const [sortDir, setSortDir] = useState<TableOrder>('desc');

	const limit = 10;
	const pagesCount = useMemo(
		() => Math.ceil((data?.getUtmStats.total || 0) / limit),
		[data?.getUtmStats.total],
	);

	useEffect(() => {
		getUtmSource({
			variables: {
				input: {
					limit,
					page: currentPage,
					search: searchValue.trim(),
					utmSortingBy: sortBy.toUpperCase() as UtmSortingBy,
					orderDirection: sortDir.toUpperCase() as OrderDirection,
				},
			},
		});
	}, [currentPage, getUtmSource, searchValue, sortBy, sortDir]);

	const columns = useMemo<ITableColumn[]>(
		() => [
			{
				name: 'manage',
				title: '',
				sortingEnabled: false,
			},
			{
				name: 'created_at',
				title: 'Дата',
				format: (value) => format(+new Date(value), 'dd.MM.yyyy'),
				tooltip: 'Первая регистрация по метке',
				sortingEnabled: false,
			},
			{
				name: 'name',
				title: 'UTM-метка',
				format: (value, row) => (
					<Tooltip content={row.utmName}>
						<div style={{textDecoration: row.utmName ? 'underline' : undefined}}>
							{value}
						</div>
					</Tooltip>
				),
			},
			{
				name: 'utm_count',
				title: 'Переходов',
				align: 'right',
				format: (value) => <PiecesCellFormat value={value as number} />,
				tooltip: 'Количество переходов по метке',
			},
			{
				name: 'users_count',
				title: 'Регистраций',
				align: 'right',
				format: (value) => <PiecesCellFormat value={value as number} />,
				tooltip: 'Число зарегистрированных по метке',
			},
			{
				name: 'users_with_payments',
				title: 'Оплативших',
				align: 'right',
				format: (value) => <PiecesCellFormat value={value as number} />,
				tooltip: 'Число оплативших людей по метке',
			},
			{
				name: 'payments_total',
				title: 'Платежей',
				align: 'right',
				format: (value) => <PiecesCellFormat value={value as number} />,
				tooltip: 'Всего платежей от пользователей с меткой',
			},
			{
				name: 'amount_total',
				title: 'Сумма',
				align: 'right',
				format: (value) => <RubleCellFormat value={value as number} />,
				tooltip: 'Сумма платежей от пользователей с меткой',
			},
			{
				name: 'utm_cost',
				title: 'Вложения',
				align: 'right',
				format: (value) => <RubleCellFormat value={value as number} />,
				tooltip: 'Стоимость размещения',
			},
			{
				name: 'registration_cost',
				title: 'СРС',
				align: 'right',
				format: (value) => <RubleCellFormat value={value as number} />,
				tooltip: 'Стоимость регистрации',
			},
			{
				name: 'payments_user_cost',
				title: 'СРА',
				align: 'right',
				format: (value) => <RubleCellFormat value={value as number} />,
				tooltip: 'Стоимость платящего пользователя',
			},
		],
		[],
	);

	const rows = useMemo<ITableRow[]>(
		() =>
			data?.getUtmStats.items.map(
				({
					id,
					utmSource,
					utmCost,
					utmName,
					utmCount,
					registrationCost,
					paymentsUserCost,
					usersWithPayments,
					paymentsTotal,
					amountTotal,
					usersCount,
					createdAt,
				}) => ({
					manage: (
						<div key={id}>
							<EditUtmSourceModal
								utmCost={utmCost}
								utmName={utmName}
								utmSource={utmSource}
								refetch={refetch}
								open={editUtmSource === id}
								onClose={() => setEditUtmSource(undefined)}
							/>

							<TooltipMui title="Редактировать" arrow>
								<IconButton onClick={() => setEditUtmSource(id)}>
									<Icon
										path={mdiSquareEditOutline}
										size={0.8}
										color={theme.palette.primary.main}
									/>
								</IconButton>
							</TooltipMui>
						</div>
					),
					name: utmSource,
					utm_cost: utmCost,
					utmName,
					utm_count: utmCount,
					registration_cost: registrationCost,
					payments_user_cost: paymentsUserCost,
					users_with_payments: usersWithPayments,
					payments_total: paymentsTotal,
					amount_total: amountTotal,
					users_count: usersCount,
					created_at: createdAt,
				}),
			) || [],

		[data?.getUtmStats.items, editUtmSource, refetch],
	);

	return (
		<>
			<div style={{marginTop: 20}}>
				<CreateUtmSourceModal
					open={open}
					onClose={() => setOpen(false)}
					refetch={refetch}
				/>
				<Button onClick={() => setOpen(true)}>+ Создать метку</Button>
			</div>

			<ContentBlock loading={loading} error={!!error}>
				<br />

				<SearchField
					value={searchValue}
					onSubmitValue={setSearchValue}
					onResetPage={() => setCurrentPage(1)}
					placeholder={'Введите имя utm-метки или её описание'}
					submitAfterClear
				/>

				<br />

				<TableAdaptive
					columns={columns}
					rows={rows}
					sortColumnName={sortBy}
					sortDirection={sortDir}
					onSorting={({columnName, direction}) => {
						setSortDir(direction);
						setSortBy(columnName);
					}}
				/>

				<br />

				{pagesCount > 1 && (
					<Grid container justifyContent="center">
						<Pagination
							page={currentPage}
							count={pagesCount}
							onChange={(_, page) => setCurrentPage(page)}
							siblingCount={1}
							boundaryCount={1}
						/>
					</Grid>
				)}
			</ContentBlock>
		</>
	);
};

export default UtmSourceTable;
