import React, {useMemo, useState} from 'react';
import {subDays} from 'date-fns';
import {DateRange} from '@mui/lab/DateRangePicker';
import {
	ContentBlock,
	DateRangeTemplate,
	ITableRow,
	SelectRangeDate,
	TableAdaptive,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {ITableColumn} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/components/common/ui/table/Table';
import {Grid, Pagination} from '@mui/material';

import {useGetOzonSupplyTracks} from 'api/user_settings';
import StatusSupplyCellFormat from 'components/common/table/StatusSupplyCellFormat';
import SwitchDisableSupply from 'components/settings_user/SwitchDisableSupply';
import {MarketNameEnum} from 'constants/appConsts';
import {PaymentStatus} from '../../gqlApp/graphql';

const WbSupplyTable: React.FC = () => {
	const [dateRangeValue, setDateRangeValue] = React.useState<DateRange<Date>>([
		subDays(new Date(), 30),
		new Date(),
	]);

	const [currentPage, setCurrentPage] = useState(1);

	const {data, loading, error, refetch} = useGetOzonSupplyTracks({
		dateRange: {
			dateTo: dateRangeValue[1]?.getTime(),
			dateFrom: dateRangeValue[0]?.getTime(),
		},
		page: currentPage,
	});

	const onDateRangeChange = (newValue: DateRange<Date>) => {
		setDateRangeValue(newValue);
	};

	const pagesCount = useMemo(
		() => Math.ceil((data?.getOzonSupplyTracks.total || 0) / 20),
		[data?.getOzonSupplyTracks.total],
	);

	const columns = useMemo<ITableColumn[]>(
		() => [
			{
				name: 'manage',
				title: 'Поиск слотов',
				tooltip:
					'Поиск слотов можно только ОТКЛЮЧИТЬ!. Включают пользователи самостоятельно',
			},
			{
				name: 'supplyOrderId',
				title: 'Id заказа на поставку',
			},
			{
				name: 'supplyOrderText',
				title: 'Текст заказа на поставку',
			},
			{
				name: 'resultStatus',
				title: 'Статус результата',
				format: (value) => <StatusSupplyCellFormat value={value as PaymentStatus} />,
			},
		],
		[],
	);

	const rows = useMemo<ITableRow[]>(
		() =>
			data?.getOzonSupplyTracks.items.map(
				({enabled, resultStatus, supplyOrderText, supplyOrderId, id}) => ({
					manage: (
						<div key={id}>
							<SwitchDisableSupply
								refetch={refetch}
								marketName={MarketNameEnum.OZON}
								id={id}
								initialValueSwitch={enabled}
							/>
						</div>
					),
					resultStatus,
					supplyOrderId,
					supplyOrderText,
				}),
			) || [],
		[data?.getOzonSupplyTracks.items, refetch],
	);

	return (
		<ContentBlock
			label="Поиск слотов по Ozon"
			loading={loading}
			error={!!error}
			rightHeaderBlock={
				<SelectRangeDate
					onChange={onDateRangeChange}
					dateRangeTemplate={DateRangeTemplate.month}
				/>
			}
		>
			<TableAdaptive columns={columns} rows={rows} height={350} />

			{pagesCount > 1 && (
				<Grid container justifyContent="center">
					<Pagination
						page={currentPage}
						count={pagesCount}
						onChange={(_, page) => setCurrentPage(page)}
						siblingCount={1}
						boundaryCount={1}
					/>
				</Grid>
			)}
		</ContentBlock>
	);
};

export default WbSupplyTable;
