import React from 'react';
import {Grid} from '@mui/material';
import {Divider} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import Box from '@mui/material/Box';

import {PaymentsStatTypeV3} from '../../../gqlApp/graphql';

interface ITooltipMore {
	payment?: PaymentsStatTypeV3;
	label?: string;
	totalSum?: number;
	totalCount?: number;
}

const TooltipPayment: React.FC<ITooltipMore> = ({payment, label, totalCount, totalSum}) => {
	if (!payment || !payment.payments.length) return <Box>Нет оплат</Box>;

	return (
		<Grid container direction={'column'} style={{marginBottom: 12}}>
			<Grid item>
				<b>{label}</b>
			</Grid>
			<Grid item container direction={'column'}>
				{payment.payments.map((pay, i) => (
					<Grid item container key={i}>
						<Grid item xs={6}>
							{pay.product}{' '}
						</Grid>
						<Grid item xs>
							{pay.count.toLocaleString()} шт
						</Grid>
						<Grid item xs style={{minWidth: 60, fontWeight: 600}}>
							{pay.amount.toLocaleString()} ₽{' '}
						</Grid>
					</Grid>
				))}
			</Grid>

			<Grid container style={{color: '#44a5ff', fontWeight: 600}}>
				<Grid item xs={6}>
					Итого{' '}
				</Grid>
				<Grid item xs>
					{' '}
					{payment.typeCount.toLocaleString()} шт
				</Grid>
				<Grid item xs style={{minWidth: 60, fontWeight: 600}}>
					{payment.typeAmount.toLocaleString()} ₽
				</Grid>
			</Grid>

			{totalCount && totalSum && (
				<>
					<Divider style={{margin: '5px 0'}} />
					<Grid container style={{fontWeight: 600, fontSize: 13}}>
						<Grid item xs={6}>
							Общий итог
						</Grid>
						<Grid item xs>
							{' '}
							{totalCount.toLocaleString()} шт
						</Grid>
						<Grid item xs style={{minWidth: 60, fontWeight: 600}}>
							{totalSum.toLocaleString()} ₽
						</Grid>
					</Grid>
				</>
			)}
		</Grid>
	);
};

export default TooltipPayment;
