import React, {useEffect, useMemo} from 'react';
import {format} from 'date-fns';
import {
	ContentBlock,
	SearchField,
	TableAdaptive,
	TablePagingPanel,
	useSearchQueryContext,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {
	ITableColumn,
	ITableRow,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/components/common/ui/table/Table';
import {useNavigate} from 'react-router';

import {useTelegramUsersQuery} from 'api/telegram_users';
import routeUrls from 'constants/routeUrls';
import css from './TelegramUsersTable.pcss';

const TelegramUsersTable: React.FC = () => {
	const [getTelegramUsers, {data, loading}] = useTelegramUsersQuery();

	const {pageSearchQuery, searchQueryContext} = useSearchQueryContext();

	const limit = 10;

	const navigate = useNavigate();

	useEffect(() => {
		getTelegramUsers({
			variables: {
				input: {
					limit,
					page: pageSearchQuery,
					search: searchQueryContext,
				},
			},
		});
	}, [getTelegramUsers, pageSearchQuery, searchQueryContext]);

	const columns = useMemo<ITableColumn[]>(
		() => [
			{
				name: 'isActive',
				title: 'Активный',
			},
			{
				name: 'name',
				title: 'Имя',
				format: (value, row) => (
					<div
						className={css.link}
						onClick={() => navigate(`${routeUrls.APP_SETTINGS_USER}?userId=${row.id}`)}
					>
						{value}
					</div>
				),
			},
			{
				name: 'lastName',
				title: 'Фамилия',
			},
			{
				name: 'username',
				title: 'Псевдоним',
			},
			{
				name: 'firstActionAt',
				title: 'Впервые активен',
				format: (value) => format(+new Date(value), 'dd.MM.yyyy'),
			},
			{
				name: 'lastActionAt',
				title: 'Последняя активность',
				format: (value) => format(+new Date(value), 'dd.MM.yyyy'),
			},
		],
		[navigate],
	);

	const rows = useMemo<ITableRow[]>(
		() =>
			data?.telegramUsers.items.map(
				({id, username, firstName, lastName, isActive, lastActionAt, firstActionAt}) => ({
					id,
					username,
					name: firstName,
					lastName,
					isActive: isActive ? 'Да' : 'Нет',
					lastActionAt,
					firstActionAt,
				}),
			) || [],
		[data?.telegramUsers.items],
	);

	return (
		<ContentBlock loading={loading}>
			<SearchField
				placeholder={'Введите имя, фамилию или псевдоним пользователя'}
				submitAfterClear
			/>

			<TableAdaptive columns={columns} rows={rows} />

			<TablePagingPanel totalCount={data?.telegramUsers.total} maxPageCount={limit} />
		</ContentBlock>
	);
};

export default TelegramUsersTable;
