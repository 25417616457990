import React from 'react';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import {Button, Tooltip} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {Grid} from '@mui/material';
import AddLinkIcon from '@mui/icons-material/AddLink';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import CreatePaymentLinkModal from 'components/settings_user/menuActions/CreatePaymentLinkModal';
import ChangeSupplyModal from 'components/settings_user/menuActions/ChangeSupplyModal';
import AddPaymentModal from 'components/settings_user/menuActions/AddPaymentModal';
import css from './MenuActions.pcss';

export enum ActionType {
	ChangeSupply = 'ChangeSupply',
	CreatePaymentLink = 'CreatePaymentLink',
	AddPayment = 'AddPayment',
}

const MenuActions = () => {
	const [openTypeModal, setOpenTypeModal] = React.useState<ActionType | undefined>(undefined);

	const menuItems = [
		{
			label: 'Изменить баланс',
			tooltip: 'Изменить кол-во автоброней',
			icon: <LoyaltyIcon />,
			component: (
				<ChangeSupplyModal
					open={openTypeModal}
					onClose={() => setOpenTypeModal(undefined)}
				/>
			),
			type: ActionType.ChangeSupply,
		},
		{
			label: 'Создать ссылку',
			tooltip: (
				<>
					Создать ссылку на оплату <br />
					подписки или пакета автоброней
				</>
			),
			icon: <AddLinkIcon />,
			component: (
				<CreatePaymentLinkModal
					open={openTypeModal}
					onClose={() => setOpenTypeModal(undefined)}
				/>
			),
			type: ActionType.CreatePaymentLink,
		},
		{
			label: 'Добавить платеж',
			tooltip: (
				<>
					Добавить совершенный платеж <br />
					подписки или пакета автоброней
				</>
			),
			icon: <PlaylistAddIcon />,
			component: (
				<AddPaymentModal open={openTypeModal} onClose={() => setOpenTypeModal(undefined)} />
			),
			type: ActionType.AddPayment,
		},
	];

	return (
		<Grid container justifyContent={'center'} style={{marginBottom: 20}}>
			{menuItems.map(({label, icon, tooltip, component, type}, i) => (
				<Grid item key={i}>
					<Tooltip content={tooltip}>
						<div>
							<Button
								classes={{root: css.button}}
								onClick={() => setOpenTypeModal(type)}
								disableRipple
							>
								{icon}
								<span style={{marginLeft: 8}}>{label}</span>
							</Button>
						</div>
					</Tooltip>
					{component}
				</Grid>
			))}
		</Grid>
	);
};

export default MenuActions;
