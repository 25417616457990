import React, {useEffect, useMemo, useState} from 'react';
import Box from '@mui/material/Box';
import {
	colors,
	Grid,
	IconButton,
	LinearProgress,
	Pagination,
	Tooltip,
	Typography,
} from '@mui/material';
import Icon from '@mdi/react';
import {
	mdiPlayCircleOutline,
	mdiSquareEditOutline,
	mdiBugOutline,
	mdiDeleteCircleOutline,
	mdiEyeOutline,
} from '@mdi/js';
import {ContentBlock, TableAdaptive} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {
	ITableColumn,
	ITableRow,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/components/common/ui/table/Table';

import EditBroadcastModal from 'components/broadcasts/EditBroadcastModal';
import TestBroadcastModal from 'components/broadcasts/TestBroadcastModal';
import {
	useBroadcastsQuery,
	useRemoveBroadcastMutation,
	useStartBroadcastMutation,
} from 'api/broadcasts';
import theme from '../../muiTheme';
import {Broadcast_BaseFragment} from '../../gqlApp/graphql';

const BroadcastsTable: React.FC = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [getBroadcasts, {data, refetch}] = useBroadcastsQuery();
	const limit = 10;
	const pagesCount = useMemo(
		() => Math.ceil((data?.broadcasts.total || 0) / limit),
		[data?.broadcasts.total],
	);

	useEffect(() => {
		getBroadcasts({
			variables: {
				input: {
					limit,
					page: currentPage,
				},
			},
		});
	}, [currentPage, getBroadcasts]);

	const [startBroadcast] = useStartBroadcastMutation();

	const columns = useMemo<ITableColumn[]>(
		() => [
			{
				name: 'manage',
				title: '',
				sortingEnabled: false,
				width: 150,
				minWidth: 150,
			},
			{
				name: 'date',
				title: 'Дата',
			},
			{
				name: 'name',
				title: 'Название',
			},
			{
				name: 'peoples',
				align: 'right',
				title: 'Кол-во пользователей',
			},
			{
				name: 'progress',
				align: 'right',
				title: 'Прогресс отправки',
				format: (value) => (
					<Box display="flex" alignItems="center">
						<Box width="100%" mr={1}>
							<LinearProgress variant="determinate" value={Number(value)} />
						</Box>
						<Box minWidth={35}>
							<Typography variant="body2" color="textSecondary">{`${Math.round(
								Number(value),
							)}%`}</Typography>
						</Box>
					</Box>
				),
			},
			{
				name: 'success',
				align: 'right',
				title: 'Успешно',
			},
			{
				name: 'error',
				align: 'right',
				title: 'С ошибкой',
			},
			{
				name: 'button',
				align: 'right',
				title: ' ',
				sortingEnabled: false,
			},
		],
		[],
	);

	const [editBroadcast, setEditBroadcast] = useState<number>();
	const [testBroadcast, setTestBroadcast] = useState<number>();

	const [removeBroadcast] = useRemoveBroadcastMutation();

	const rows = useMemo<ITableRow[]>(
		() =>
			data?.broadcasts.items.map((broadcast: Broadcast_BaseFragment, index) => {
				const {
					createdAt,
					id,
					name,
					text,
					errorCount,
					recipientCount,
					successCount,
					status,
				} = broadcast;

				const manage = [
					<EditBroadcastModal
						broadcast={broadcast}
						open={editBroadcast === id}
						onClose={() => setEditBroadcast(undefined)}
						mode={status === 'DONE' ? 'view' : 'edit'}
					/>,
				];

				if (status !== 'DONE') {
					manage.push(
						<div key={index}>
							<Tooltip title="Редактировать" arrow>
								<IconButton onClick={() => setEditBroadcast(id)}>
									<Icon
										path={mdiSquareEditOutline}
										size={0.8}
										color={theme.palette.primary.main}
									/>
								</IconButton>
							</Tooltip>

							{status === 'WAIT' && (
								<Tooltip title="Начать рассылку" arrow>
									<IconButton
										onClick={() => {
											if (!window.confirm('Точно начать рассылку?')) {
												return;
											}

											startBroadcast({variables: {id}}).then(
												() =>
													refetch &&
													refetch({
														input: {
															limit,
															page: currentPage,
														},
													}),
											);
										}}
									>
										<Icon
											path={mdiPlayCircleOutline}
											size={0.8}
											color={colors.green[500]}
										/>
									</IconButton>
								</Tooltip>
							)}

							<TestBroadcastModal
								broadcast={broadcast}
								open={testBroadcast === id}
								onClose={() => setTestBroadcast(undefined)}
							/>

							<Tooltip title="Тестовая рассылка" arrow>
								<IconButton onClick={() => setTestBroadcast(id)}>
									<Icon
										path={mdiBugOutline}
										size={0.8}
										color={colors.green[500]}
									/>
								</IconButton>
							</Tooltip>

							<Tooltip title="Удалить рассылку" arrow>
								<IconButton
									onClick={() =>
										window.confirm('Точно удалить?') &&
										removeBroadcast({variables: {id}})
											.then(
												() =>
													refetch &&
													refetch({
														input: {
															limit,
															page: currentPage,
														},
													}),
											)
											.catch((err) => console.log(err))
									}
								>
									<Icon
										path={mdiDeleteCircleOutline}
										size={0.8}
										color={colors.red[500]}
									/>
								</IconButton>
							</Tooltip>
						</div>,
					);
				}

				if (status === 'DONE') {
					manage.push(
						<Tooltip title="Режим просмотра" arrow>
							<IconButton onClick={() => setEditBroadcast(id)}>
								<Icon
									path={mdiEyeOutline}
									size={0.8}
									color={theme.palette.primary.main}
								/>
							</IconButton>
						</Tooltip>,
					);
				}

				return {
					manage,
					id: `#${id}`,
					name,
					text,
					peoples: recipientCount,
					progress:
						recipientCount !== 0
							? (((successCount + errorCount) * 100) / recipientCount).toFixed()
							: 0,
					error: errorCount,
					success: successCount,
					date: createdAt,
				};
			}) || [],
		[
			currentPage,
			data?.broadcasts.items,
			editBroadcast,
			refetch,
			removeBroadcast,
			startBroadcast,
			testBroadcast,
		],
	);

	return (
		<ContentBlock>
			<TableAdaptive columns={columns} rows={rows} />

			{pagesCount > 1 && (
				<>
					<Grid container justifyContent="center">
						<Pagination
							page={currentPage}
							count={pagesCount}
							onChange={(_, page) => setCurrentPage(page)}
							siblingCount={1}
							boundaryCount={1}
						/>
					</Grid>

					<br />
				</>
			)}
		</ContentBlock>
	);
};

export default BroadcastsTable;
