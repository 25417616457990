import React from 'react';
import {createRoot} from 'react-dom/client';

// eslint-disable-next-line import/no-unassigned-import
import './index.css';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	ArcElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import * as serviceWorker from './serviceWorker';
import Root from './Root';

ChartJS.register(
	CategoryScale,
	LinearScale,
	ArcElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Root />);

serviceWorker.unregister();
