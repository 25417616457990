import React, {useEffect, useMemo, useState} from 'react';
import {FormikHelpers, useFormik} from 'formik';
import {ApolloError} from '@apollo/client';
import {boolean, mixed, object, SchemaOf, string} from 'yup';
import {Grid, Link, TextareaAutosize, TextField, Theme, useMediaQuery} from '@mui/material';
import last from 'lodash/last';
import {
	Button,
	ButtonTelegramBroadcast,
	ContentBlock,
	EmojiButton,
	PreviewBroadcasts,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import Dropzone from 'components/common/ui/Dropzone';

import appConsts from 'constants/appConsts';
import {
	Broadcast_BaseFragment,
	BroadcastEntity,
	CreateBroadcastInput,
	EditBroadcastInput,
} from '../../gqlApp/graphql';

interface IBroadcastForm {
	onSubmit: (
		values: (CreateBroadcastInput | EditBroadcastInput) & {file?: any | null},
		formikHelpers: FormikHelpers<
			(CreateBroadcastInput | EditBroadcastInput) & {file?: any | null}
		>,
	) => void | (Promise<any> & (() => void));
	broadcast?: Broadcast_BaseFragment;
	loading: boolean;
	error?: ApolloError;
	mode?: 'edit' | 'view';
	backgroundSlimCSS?: boolean;
}

const validationSchema: SchemaOf<
	(CreateBroadcastInput | EditBroadcastInput) & {file?: any | null}
> = object().shape({
	name: string().trim().required('Укажите название'),
	text: string().trim().required('Укажите текст'),
	file: mixed(),
	deleteImage: boolean(),
	buttonText: string(),
	buttonUrl: string().nullable(),
});

const BroadcastForm: React.FC<IBroadcastForm> = ({
	broadcast,
	loading,
	error,
	onSubmit,
	mode,
	backgroundSlimCSS,
}) => {
	const initialValues = useMemo<
		((CreateBroadcastInput | EditBroadcastInput) & {file?: any | null}) | BroadcastEntity
	>(
		() =>
			broadcast || {
				name: '',
				text: '',
				deleteImage: false,
				buttonText: undefined,
				buttonUrl: undefined,
			},
		[broadcast],
	);

	const isEditMode = useMemo(() => !!broadcast, [broadcast]);

	const isFromLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
	});

	const [imagePath, setImagePath] = useState(broadcast?.imagePath);

	useEffect(() => {
		if (formik.values.file || imagePath) {
			formik.setFieldValue('deleteImage', false);

			return;
		}

		formik.setFieldValue('deleteImage', true);
		// eslint-disable-next-line
	}, [formik.values.file, imagePath]);

	const [cursorPosition, setCursorPosition] = useState(0);
	const [openAddButtonForm, setOpenAddButtonForm] = useState(!!broadcast?.buttonText);

	const handleCursorPosition = (event: any) => {
		setCursorPosition(event.target.selectionStart);
		formik.handleChange(event);
	};

	return (
		<ContentBlock loading={loading} error={!!error}>
			<form onSubmit={formik.handleSubmit}>
				<Grid container direction={isFromLg ? 'column' : undefined}>
					<Grid
						item
						container
						xs={isFromLg ? 12 : 5}
						direction={'column'}
						spacing={2}
						style={{marginRight: 30}}
					>
						<Grid item>
							<TextField
								name="name"
								defaultValue={formik.values.name}
								placeholder="Название рассылки [не публикуется]"
								variant={'outlined'}
								onChange={formik.handleChange}
								fullWidth
								error={formik.touched.name && Boolean(formik.errors.name)}
								helperText={formik.touched.name && formik.errors.name}
							/>
						</Grid>
						<Grid item>
							<TextField
								inputProps={{component: TextareaAutosize, minRows: 8}}
								multiline
								name="text"
								value={formik.values.text}
								placeholder="Введите текст рассылки"
								variant={'outlined'}
								onFocus={handleCursorPosition}
								onClick={handleCursorPosition}
								onChange={handleCursorPosition}
								onKeyUp={handleCursorPosition}
								fullWidth
								error={formik.touched.text && Boolean(formik.errors.text)}
								helperText={formik.touched.text && formik.errors.text}
							/>
						</Grid>

						<EmojiButton
							formik={formik}
							nameField={'text'}
							cursorPosition={cursorPosition}
							setCursorPosition={setCursorPosition}
						/>

						<Grid item>
							Поддерживаемые{' '}
							<Link
								href="https://core.telegram.org/bots/api#html-style"
								target="_blank"
							>
								html теги
							</Link>
						</Grid>
						<Grid item hidden={mode === 'view'}>
							<Dropzone
								onDrop={(files) => formik.setFieldValue('file', last(files))}
							/>
						</Grid>

						<ButtonTelegramBroadcast
							formik={formik}
							mode={mode}
							openAddButtonForm={openAddButtonForm}
							setOpenAddButtonForm={setOpenAddButtonForm}
						/>

						<Grid item hidden={mode === 'view'} alignSelf={'center'}>
							<Button loading={loading} type="submit">
								{isEditMode ? 'Сохранить' : 'Создать'} рассылку
							</Button>
						</Grid>
					</Grid>

					<Grid container xs={isFromLg ? 12 : 6}>
						<PreviewBroadcasts
							formik={formik}
							openAddButtonForm={openAddButtonForm}
							imagePath={imagePath}
							setImagePath={setImagePath}
							mode={mode}
							backgroundSlimCSS={backgroundSlimCSS}
							GRAPHQL_URI={appConsts.GRAPHQL_URI ?? ''}
						/>
					</Grid>
				</Grid>
			</form>
		</ContentBlock>
	);
};

export default BroadcastForm;
