import React, {useMemo} from 'react';
import {object, string} from 'yup';
import {useFormik} from 'formik';
import {DialogContent, Grid, TextField} from '@mui/material';
import {Button, Modal, useSearchQueryContext} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import {useSnackbar} from 'notistack';
import {useSetUserSettings} from 'api/user_settings';
import {ActionType} from 'components/settings_user/menuActions/MenuActions';
import {useGetUserData} from 'api/telegram_users';
import css from './ChangeSupplyModal.pcss';

interface IFormikValues {
	freeSupplyCount?: number;
}

interface IChangeSupplyModal {
	open?: ActionType;
	onClose: () => void;
}

const ChangeSupplyModal: React.FC<IChangeSupplyModal> = ({open, onClose}) => {
	const {userIdQuery} = useSearchQueryContext();

	const {refetch} = useGetUserData();
	const [updateSettings, {loading, error}] = useSetUserSettings();

	const {enqueueSnackbar} = useSnackbar();

	const onSubmit = ({freeSupplyCount}: IFormikValues) =>
		updateSettings({
			variables: {
				input: {
					userId: userIdQuery ?? 0,
					freeSupplyCount,
				},
			},
		})
			.then(() => {
				enqueueSnackbar('Количество автоброней изменено', {variant: 'success'});
				refetch();
				onClose();
			})
			.catch((error) => {
				enqueueSnackbar(error.message, {variant: 'error'});
			});

	const validationSchema = useMemo(
		() =>
			object().shape({
				freeSupplyCount: string().trim().required('Введите кол-во автоброней'),
			}),
		[],
	);

	const initialValues: IFormikValues = {
		freeSupplyCount: undefined,
	};

	const formik = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
	});

	return (
		<div>
			<Modal open={open === ActionType.ChangeSupply} onClose={onClose}>
				<DialogContent>
					<Grid container alignItems={'center'} spacing={3} direction={'column'}>
						<Grid item className={css.title}>
							Изменение количества автоброней{' '}
							{/*<div style={{fontSize: 12}}>[Доступно только ROOT Админам]</div>*/}
						</Grid>
						<Grid item>
							<form
								onSubmit={(e) => {
									formik.handleSubmit(e);
								}}
							>
								<TextField
									fullWidth
									placeholder={'Введите кол-во автоброней'}
									value={formik.values.freeSupplyCount}
									name="freeSupplyCount"
									type={'number'}
									onChange={formik.handleChange}
									InputProps={{
										classes: {focused: css.focusedInput, error: css.errorInput},
										className: css.input,
										endAdornment: (
											<Button
												type={'submit'}
												classes={{root: css.buttonRoot}}
												size={'medium'}
												loading={loading}
											>
												Изменить
											</Button>
										),
									}}
									error={!!error}
								/>
							</form>
						</Grid>
						<Grid item xs={12}></Grid>
					</Grid>
				</DialogContent>
			</Modal>
		</div>
	);
};

export default ChangeSupplyModal;
