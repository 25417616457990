import React, {useMemo, useState} from 'react';
import {format, subDays} from 'date-fns';
import {DateRange} from '@mui/lab/DateRangePicker';
import {
	ContentBlock,
	DateRangeTemplate,
	ITableRow,
	SelectRangeDate,
	TableAdaptive,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {ITableColumn} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/components/common/ui/table/Table';
import {Grid, Pagination} from '@mui/material';

import {useGetWbPaidSupplyTracks} from 'api/user_settings';
import StatusSupplyCellFormat from 'components/common/table/StatusSupplyCellFormat';
import SwitchDisableWbPaidSupply from 'components/settings_user/SwitchDisableWbPaidSupply';
import DeleteWbPaidSupplyButton from 'components/settings_user/DeleteWbPaidSupplyButton';
import {PaymentStatus} from '../../gqlApp/graphql';

const WbPaidSupplyTable: React.FC = () => {
	const [dateRangeValue, setDateRangeValue] = React.useState<DateRange<Date>>([
		subDays(new Date(), 30),
		new Date(),
	]);

	const [currentPage, setCurrentPage] = useState(1);

	const {data, loading, error, refetch} = useGetWbPaidSupplyTracks({
		dateRange: {
			dateTo: dateRangeValue[1]?.getTime(),
			dateFrom: dateRangeValue[0]?.getTime(),
		},
		page: currentPage,
	});

	const onDateRangeChange = (newValue: DateRange<Date>) => {
		setDateRangeValue(newValue);
	};

	const pagesCount = useMemo(
		() => Math.ceil((data?.getWbPaidSupplyTracks.total || 0) / 20),
		[data?.getWbPaidSupplyTracks.total],
	);

	const columns = useMemo<ITableColumn[]>(
		() => [
			{
				name: 'date',
				title: 'Дата',
				format: (value) => format(+new Date(value), 'dd.MM.yyyy'),
			},
			{
				name: 'supplyId',
				title: 'ID поставки',
			},
			{
				name: 'deleted',
				title: 'Отмена',
				tooltip: 'Полная отмена автобронирования с возможностью возврата автоброни ',
			},
			{
				name: 'manage',
				title: 'Активность',
				tooltip:
					'Перевод автобронирования в НЕАКТИВНОЕ. После перевода в полях изменяются значения:' +
					' "включен поиск - Нет", "Статус - Срок для поиска истёк"  и у пользователя в' +
					' боте будет возможность вручную перенести поставку на новые даты',
			},
			{
				name: 'enabled',
				title: 'Включен поиск',
			},
			{
				name: 'status',
				title: 'Статус',
				format: (value) => <StatusSupplyCellFormat value={value as PaymentStatus} />,
				tooltip:
					'Поставка активна, но с одним исключением, если ' +
					'"включен поиск == нет", то значит она ещё не оплачена ' +
					'или отменена нами',
			},
			{
				name: 'warehouseName',
				title: 'Склад',
			},
			{
				name: 'supplyType',
				title: 'Тип',
			},
			{
				name: 'coefficient',
				title: 'Коэффициент',
			},
			{
				name: 'supplyOrderText',
				title: 'Текст заказа на поставку',
			},
			{
				name: 'resultStatus',
				title: 'Статус результата',
				format: (value) => <StatusSupplyCellFormat value={value as PaymentStatus} />,
			},
			{
				name: 'error',
				title: 'Причина ошибок',
			},
		],
		[],
	);

	const rows = useMemo<ITableRow[]>(
		() =>
			data?.getWbPaidSupplyTracks.items.map(
				({
					id,
					createdAt,
					status,
					supplyId,
					error,
					supplyType,
					enabled,
					coefficient,
					supplyOrderText,
					resultStatus,
					warehouseName,
				}) => ({
					manage: (
						<div key={id}>
							<SwitchDisableWbPaidSupply
								id={id}
								refetch={refetch}
								initialValueSwitch={enabled}
								isShow={enabled && status === 'ACTIVE'}
							/>
						</div>
					),
					deleted: (
						<div key={id}>
							<DeleteWbPaidSupplyButton
								id={id}
								refetch={refetch}
								isShow={enabled && status !== 'ACTIVE'}
							/>
						</div>
					),
					date: createdAt,
					warehouseName,
					supplyId,
					supplyType,
					enabled: enabled ? 'Да' : 'Нет',
					coefficient,
					supplyOrderText,
					resultStatus,
					error,
					status,
				}),
			) || [],
		[data?.getWbPaidSupplyTracks.items, refetch],
	);

	return (
		<ContentBlock
			label="Платные автобронирования по WB"
			loading={loading}
			error={!!error}
			rightHeaderBlock={
				<SelectRangeDate
					onChange={onDateRangeChange}
					dateRangeTemplate={DateRangeTemplate.month}
				/>
			}
		>
			<TableAdaptive columns={columns} rows={rows} height={350} />

			{pagesCount > 1 && (
				<Grid container justifyContent="center">
					<Pagination
						page={currentPage}
						count={pagesCount}
						onChange={(_, page) => setCurrentPage(page)}
						siblingCount={1}
						boundaryCount={1}
					/>
				</Grid>
			)}
		</ContentBlock>
	);
};

export default WbPaidSupplyTable;
