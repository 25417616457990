import {useLazyQuery, useQuery} from '@apollo/client';
import {useSearchQueryContext} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import {graphql} from '../gqlApp';

const BROADCASTS_QUERY = graphql(`
	query TelegramUsers($input: TelegramUsersArgs!) {
		telegramUsers(input: $input) {
			total
			items {
				id
				isActive
				firstName
				lastName
				username
				firstActionAt
				lastActionAt
			}
		}
	}
`);

export const useTelegramUsersQuery = () => useLazyQuery(BROADCASTS_QUERY);

const GET_USER_DATA_QUERY = graphql(`
	query GetUserData($input: TelegramUserInput!) {
		getUserData(input: $input) {
			isActive
			id
			firstName
			lastName
			username
			firstActionAt
			lastActionAt
			activeToDate
			isSubscriptionActive
			freeSupplyCount
			autoPayment
		}
	}
`);

export const useGetUserData = () => {
	const {userIdQuery} = useSearchQueryContext();

	const {data, loading, error, refetch} = useQuery(GET_USER_DATA_QUERY, {
		variables: {
			input: {
				userId: userIdQuery ?? 0,
			},
		},
	});

	return {
		data,
		loading,
		error,
		refetch,
	};
};
