import React from 'react';
import {subDays} from 'date-fns';
import {DateRange} from '@mui/lab/DateRangePicker';
import {GridProps} from '@mui/material';
import {
	ContentBlock,
	DateRangeTemplate,
	SelectRangeDate,
	VisualChart,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import {useUniqueTelegramUsersActivityQuery} from 'api/users';

const UniqueTelegramUsersActivityChart: React.FC<GridProps> = () => {
	const [dateRangeValue, setDateRangeValue] = React.useState<DateRange<Date>>([
		subDays(new Date(), 30),
		new Date(),
	]);

	const onDateRangeChange = (newValue: DateRange<Date>) => {
		setDateRangeValue(newValue);
	};

	const {data, loading, error} = useUniqueTelegramUsersActivityQuery({
		input: {
			dateFrom: dateRangeValue[0]?.getTime(),
			dateTo: dateRangeValue[1]?.getTime(),
		},
	});

	return (
		<ContentBlock
			label="DAU"
			subLabel={'уникальные пользователи бота по дням'}
			rightHeaderBlock={
				<SelectRangeDate
					onChange={onDateRangeChange}
					dateRangeTemplate={DateRangeTemplate.month}
				/>
			}
			loading={loading}
			error={!!error}
		>
			<VisualChart
				datasets={data.datasets}
				labels={data.labels}
				loading={loading}
				hideLegend
			/>
		</ContentBlock>
	);
};

export default UniqueTelegramUsersActivityChart;
