import {useLazyQuery, useMutation} from '@apollo/client';
import {graphql} from '../gqlApp';

const UTM_STATS_QUERY = graphql(`
	query GetUtmStats($input: UtmStatsArgs!) {
		getUtmStats(input: $input) {
			items {
				id
				utmName
				utmSource
				utmCount
				createdAt
				usersCount
				usersWithPayments
				paymentsTotal
				amountTotal
				utmCost
				registrationCost
				paymentsUserCost
			}
			total
		}
	}
`);

export const useUtmStatsQuery = () => useLazyQuery(UTM_STATS_QUERY);

const UPDATE_UTM_MUTATION = graphql(`
	mutation UpdateUtmStat($input: UtmStatInput!) {
		updateUtmStat(input: $input)
	}
`);

export const useUpdateUTM = () => useMutation(UPDATE_UTM_MUTATION);

const ADD_UTM_MUTATION = graphql(`
	mutation AddUtmSource($input: UtmStatInput!) {
		addUtmSource(input: $input)
	}
`);

export const useAddUTM = () => useMutation(ADD_UTM_MUTATION);
