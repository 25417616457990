import React from 'react';

import PaymentChart from 'components/dashboard/PaymentChart';
import AppWrapper from 'components/common/AppWrapper';

const PayPage: React.FC = () => (
	<AppWrapper title={'Платежи'}>
		<PaymentChart />

		<div style={{height: 100}} />
	</AppWrapper>
);

export default PayPage;
