import React from 'react';
import {CardContent, Dialog, DialogContent, DialogProps, DialogTitle} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';

import {useUpdateUTM} from 'api/utm_source';
import FormUtmSource from 'components/utm_source/FormUtmSource';

interface IEditUtmSourceModal {
	utmCost: number | null | undefined;
	utmName: string | null | undefined;
	utmSource: string;
	refetch: () => void;
}

const EditUtmSourceModal: React.FC<IEditUtmSourceModal & DialogProps> = (props) => {
	const {utmCost, utmName, utmSource, refetch, onClose} = props;

	const [updateUTM, {loading}] = useUpdateUTM();

	const {enqueueSnackbar} = useSnackbar();

	const formik = useFormik({
		initialValues: {
			utmCost,
			utmName,
			utmSource,
		},
		onSubmit: ({utmCost, utmName, utmSource}) =>
			updateUTM({
				variables: {
					input: {
						utmCost,
						utmName: utmName ? utmName : null,
						utmSource,
					},
				},
			})
				.then(() => {
					enqueueSnackbar('Настройки успешно сохранены', {variant: 'success'});
					onClose && onClose({}, 'backdropClick');
					refetch();
				})
				.catch((error) => {
					enqueueSnackbar(error.message, {variant: 'error'});
				}),
	});

	return (
		<Dialog {...props} fullWidth>
			<DialogTitle style={{textAlign: 'center'}}>Редактирование UTM-метки</DialogTitle>

			<DialogContent>
				<CardContent>
					<FormUtmSource formik={formik} loading={loading} mode={'edit'} />
				</CardContent>
			</DialogContent>
		</Dialog>
	);
};

export default EditUtmSourceModal;
