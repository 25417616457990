import React from 'react';

import BroadcastsTable from 'components/broadcasts/BroadcastsTable';
import AppWrapper from 'components/common/AppWrapper';

const BroadcastsPage: React.FC = () => (
	<AppWrapper title={'Список рассылок'}>
		<BroadcastsTable />
	</AppWrapper>
);

export default BroadcastsPage;
