import React, {useCallback, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {FormikHelpers, useFormik} from 'formik';
import {object, string, ref} from 'yup';
import {GraphQLError} from 'graphql';
import {Grid, Input, TextField} from '@mui/material';
import Button from '@mui/material/Button';

import AuthWrapper from 'components/auth/AuthWrapper';
import {setNewPasswordMutation} from 'api/users';
import appConsts from 'constants/appConsts';
import routeUrls from 'constants/routeUrls';
import {NewPasswordInput} from '../../gqlApp/graphql';

const initialValues: NewPasswordInput & {newPasswordMore: string} = {
	password: '',
	code: '',
	newPasswordMore: '',
};

const validationSchema = object().shape({
	password: string()
		.min(
			appConsts.MIN_PASSWORD_LENGTH,
			`Укажите не менее ${appConsts.MIN_PASSWORD_LENGTH} символов`,
		)
		.required('Укажите пароль'),
	newPasswordMore: string()
		.oneOf([ref('password'), undefined], 'Пароли не совпадают')
		.required('Повторите пароль'),

	code: string().required(),
});

const PassRecoveryPage: React.FC = () => {
	const {code} = useParams<{code?: string}>();

	const [success, setSuccess] = useState(false);

	const onSubmit = useCallback(
		(
			{code, password}: NewPasswordInput & {newPasswordMore: string},
			actions: FormikHelpers<NewPasswordInput & {newPasswordMore: string}>,
		) => {
			setNewPasswordMutation({input: {code, password}})
				.then(() => setSuccess(true))
				.catch((err) => {
					err.graphQLErrors.forEach((error: GraphQLError) => {
						switch (error.message) {
							case 'RESET_CODE_ERROR_MESSAGE':
								actions.setErrors({
									newPasswordMore: 'Ссылка на восстановление пароля устарела',
								});

								break;

							default:
								actions.setErrors({
									newPasswordMore: 'Ошибка. Обратитесь к разработчикам',
								});
						}

						actions.setSubmitting(false);
					});
				});
		},
		[],
	);

	const formik = useFormik({
		initialValues: {...initialValues, code: code || ''},
		validationSchema,
		onSubmit,
	});

	return (
		<AuthWrapper title={'Восстановление'}>
			<form onSubmit={formik.handleSubmit}>
				<Input name="code" type="hidden" />

				{!success && (
					<Grid container spacing={2} direction={'column'}>
						<Grid item xs>
							Придумайте новый пароль
						</Grid>
						<Grid item xs>
							<TextField
								onChange={(e) =>
									formik.setFieldValue(
										e.target.name,
										e.target.value,
										!!Object.keys(formik.errors).length,
									)
								}
								error={!!formik.errors.password}
								helperText={formik.errors?.password}
								fullWidth
								name="password"
								placeholder="Новый пароль"
								disabled={success}
							/>
						</Grid>

						<Grid item xs>
							<TextField
								onChange={(e) =>
									formik.setFieldValue(
										e.target.name,
										e.target.value,
										!!Object.keys(formik.errors).length,
									)
								}
								error={!!formik.errors.newPasswordMore}
								helperText={formik.errors?.newPasswordMore}
								fullWidth
								name="newPasswordMore"
								placeholder="Новый пароль еще раз"
								disabled={success}
							/>
						</Grid>
					</Grid>
				)}

				<Grid container spacing={2} direction={'column'}>
					<Grid item xs>
						{success && (
							<>
								Пароль успешно восстановлен, <br /> теперь вы можете{' '}
								<Link to={routeUrls.AUTH_LOGIN}>войти</Link>
							</>
						)}
					</Grid>

					{!success && (
						<Grid item xs>
							<Button
								type="submit"
								variant="outlined"
								color="primary"
								size="large"
								fullWidth
								disabled={success}
							>
								Продолжить
							</Button>
						</Grid>
					)}
				</Grid>
			</form>
		</AuthWrapper>
	);
};

export default PassRecoveryPage;
