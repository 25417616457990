import React from 'react';
import {useSnackbar} from 'notistack';
import {useFormik} from 'formik';
import {object} from 'yup';
import {Button, Divider, useSearchQueryContext} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {Grid, TextField} from '@mui/material';

import {useCreateSuccessSubscriptionPayment} from 'api/user_settings';

interface ISubscriptionPaymentLink {}

const SubscriptionLinkPayment: React.FC<ISubscriptionPaymentLink> = () => {
	const {userIdQuery} = useSearchQueryContext();
	const [createCardSubscription, {loading}] = useCreateSuccessSubscriptionPayment();

	const {enqueueSnackbar} = useSnackbar();

	const formik = useFormik({
		initialValues: {
			daysCount: undefined,
			price: undefined,
			freeSupplyCount: undefined,
		},
		onSubmit: ({daysCount, price, freeSupplyCount}) => {
			if (!daysCount || !price || !freeSupplyCount || !userIdQuery) return undefined;

			return createCardSubscription({
				variables: {
					input: {
						userId: userIdQuery,
						freeSupplyCount,
						price,
						daysCount,
					},
				},
			})
				.then(({data}) => {
					enqueueSnackbar(<>Платеж добавлен. Обновите историю</>, {variant: 'success'});
					console.log(data);
				})
				.catch((error) => {
					enqueueSnackbar(
						<>
							Ошибка: <br />${error.message}
						</>,
						{variant: 'error'},
					);
				});
		},
		validationSchema: object().shape({}),
	});

	return (
		<form onSubmit={formik.handleSubmit} style={{display: 'flex', justifyContent: 'center'}}>
			<Grid
				container
				direction={'column'}
				spacing={2}
				justifyContent={'center'}
				style={{width: 500}}
			>
				<Grid item container spacing={2} direction={'column'}>
					<Grid item>Количество дней в подписке</Grid>
					<Grid item>
						<TextField
							name="daysCount"
							type="number"
							defaultValue={formik.values.daysCount}
							placeholder="шт"
							variant={'filled'}
							onChange={formik.handleChange}
							fullWidth
							error={formik.touched.daysCount && Boolean(formik.errors.daysCount)}
							helperText={formik.touched.daysCount && formik.errors.daysCount}
						/>
					</Grid>
				</Grid>
				<Grid item container direction={'column'} spacing={2}>
					<Grid item>Количество автоброней</Grid>
					<Grid item>
						<TextField
							type={'number'}
							name="freeSupplyCount"
							defaultValue={formik.values.freeSupplyCount}
							variant={'filled'}
							placeholder="шт"
							onChange={formik.handleChange}
							fullWidth
							error={
								formik.touched.freeSupplyCount &&
								Boolean(formik.errors.freeSupplyCount)
							}
							helperText={
								formik.touched.freeSupplyCount && formik.errors.freeSupplyCount
							}
						/>
					</Grid>
				</Grid>

				<Grid item container direction={'column'} spacing={2}>
					<Grid item>Цена</Grid>
					<Grid item>
						<TextField
							type={'number'}
							name="price"
							defaultValue={formik.values.price}
							placeholder="₽"
							variant={'filled'}
							onChange={formik.handleChange}
							fullWidth
							error={formik.touched.price && Boolean(formik.errors.price)}
							helperText={formik.touched.price && formik.errors.price}
						/>
					</Grid>
				</Grid>

				<Divider orientation={'horizontal'} style={{margin: '30px 0 10px'}} />

				<Grid item style={{textAlign: 'center'}}>
					<Button
						type={'submit'}
						disabled={
							!formik.values.price ||
							!formik.values.freeSupplyCount ||
							!formik.values.daysCount
						}
						loading={loading}
					>
						Добавить платеж [подписка]
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default SubscriptionLinkPayment;
