import React from 'react';
import {Grid, Theme, useMediaQuery} from '@mui/material';

import AppWrapper from 'components/common/AppWrapper';
import GeneralInformationAboutUser from 'components/settings_user/GeneralInformationAboutUser';
import PaymentsUserTable from 'components/settings_user/PaymentsUserTable';
import WbPaidSupplyTable from 'components/settings_user/WbPaidSupplyTable';
import WbSupplyTable from 'components/settings_user/WbSupplyTable';
import OzonSupplyTable from 'components/settings_user/OzonSupplyTable';
import MenuActions from 'components/settings_user/menuActions/MenuActions';

const SettingsUserPage: React.FC = () => {
	const isFromLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));

	return (
		<AppWrapper title={'Настройки пользователя'}>
			<Grid container spacing={2} direction={isFromLg ? 'column' : 'row'} wrap={'nowrap'}>
				<Grid item xs={5}>
					<GeneralInformationAboutUser />
				</Grid>
				{isFromLg && (
					<Grid>
						<MenuActions />
					</Grid>
				)}
				<Grid item xs>
					<PaymentsUserTable />
				</Grid>
			</Grid>

			{!isFromLg && <MenuActions />}

			<WbPaidSupplyTable />

			<WbSupplyTable />

			<OzonSupplyTable />
		</AppWrapper>
	);
};

export default SettingsUserPage;
