import React from 'react';
import {Grid, TextField} from '@mui/material';
import {isNull} from 'lodash';
import css from 'components/common/AppNavigator.pcss';
import {Button} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

interface IFormUtmSource {
	formik: any;
	loading?: boolean;
	mode?: 'create' | 'edit';
}

const FormUtmSource: React.FC<IFormUtmSource> = (props) => {
	const {formik, loading, mode} = props;

	return (
		<form onSubmit={formik.handleSubmit}>
			<Grid container>
				<Grid item container direction={'column'} style={{marginTop: 30}}>
					<Grid item style={{marginBottom: 10}}>
						UTM-метка {mode === 'edit' && '(Недоступно для редактирования)'}
					</Grid>

					<Grid item xs>
						<TextField
							disabled={mode === 'edit'}
							fullWidth
							placeholder={'Название метки'}
							name="utmSource"
							value={isNull(formik.values.utmSource) ? '' : formik.values.utmSource}
							classes={{root: css.input}}
							onChange={formik.handleChange}
							error={formik.touched.utmSource && !!formik.errors.utmSource}
							helperText={formik.touched.utmSource && formik.errors.utmSource}
						/>
					</Grid>
				</Grid>
				<Grid item container direction={'column'} style={{marginTop: 30}}>
					<Grid item style={{marginBottom: 10, fontWeight: 500}}>
						Описание метки
					</Grid>

					<Grid item xs>
						<TextField
							onChange={formik.handleChange}
							fullWidth
							name="utmName"
							placeholder="Описание метки"
							value={formik.values.utmName}
							classes={{root: css.input}}
						/>
					</Grid>
				</Grid>
				<Grid item container direction={'column'} style={{marginTop: 30, fontWeight: 500}}>
					<Grid item style={{marginBottom: 10}}>
						Стоимость размещения
					</Grid>

					<Grid item xs>
						<TextField
							type={'number'}
							onChange={formik.handleChange}
							fullWidth
							name="utmCost"
							placeholder="₽"
							value={formik.values.utmCost}
							classes={{root: css.input}}
							error={formik.touched.utmCost && !!formik.errors.utmCost}
							helperText={formik.touched.utmCost && formik.errors.utmCost}
						/>
					</Grid>
				</Grid>
				<Grid item xs style={{marginTop: 30}}>
					<Button fullWidth type={'submit'} loading={loading}>
						{mode === 'edit' ? 'Сохранить' : 'Создать'}
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default FormUtmSource;
