import React, {useCallback, useMemo, useState} from 'react';
import {Checkbox, Grid, IconButton, Popover} from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import {useSnackbar} from 'notistack';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';

import {useDeleteWbPaidSupply} from 'api/user_settings';
import css from 'components/common/ui/Confirm.pcss';

interface DeleteWbPaidSupplyButtonProps {
	id: number;
	isShow?: boolean;
	refetch: () => void;
}

const DeleteWbPaidSupplyButton: React.FC<DeleteWbPaidSupplyButtonProps> = ({
	id,
	refetch,
	isShow,
}) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const open = Boolean(anchorEl);

	const [deleteWbPaidSupply] = useDeleteWbPaidSupply();

	const [compensate, setCompensate] = useState<boolean>(true);

	const {enqueueSnackbar} = useSnackbar();

	const handleDeleteWbPaidSupply = useCallback(() => {
		deleteWbPaidSupply &&
			deleteWbPaidSupply({
				variables: {
					input: {
						supplyId: id,
						compensate,
					},
				},
			})
				.then(() => refetch())
				.catch(() => {
					enqueueSnackbar('Ошибка ', {variant: 'error'});
				});
	}, [compensate, deleteWbPaidSupply, enqueueSnackbar, refetch, id]);

	const message = useMemo(
		() => (
			<Grid container direction={'column'} style={{padding: 20, fontSize: 14}}>
				<Grid item>
					Точно полностью отменить <br /> автобронирование?
				</Grid>
				<Grid item>
					<Checkbox
						checked={compensate}
						onClick={() => {
							setCompensate(!compensate);
						}}
					/>
					Вернуть автобронь
				</Grid>
				<Grid container spacing={2} justifyContent={'center'}>
					<Grid item>
						<Button
							size={'small'}
							onClick={handleDeleteWbPaidSupply}
							className={css.btn}
							color={'success'}
						>
							Да
						</Button>
					</Grid>

					<Grid item>
						<Button onClick={() => setAnchorEl(null)} className={css.btn}>
							Нет
						</Button>
					</Grid>
				</Grid>
			</Grid>
		),
		[compensate, handleDeleteWbPaidSupply],
	);

	return (
		<>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				{message}
			</Popover>
			<IconButton
				onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
					setAnchorEl(event.currentTarget)
				}
				disabled={!isShow}
			>
				{isShow ? <SearchIcon /> : <SearchOffIcon />}
			</IconButton>
		</>
	);
};

export default DeleteWbPaidSupplyButton;
