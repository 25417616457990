import React from 'react';
import {useSnackbar} from 'notistack';
import {useFormik} from 'formik';
import {object} from 'yup';
import {Button, Divider, useSearchQueryContext} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {Grid, TextField} from '@mui/material';

import {useCreateSuccessSupplyPayment} from 'api/user_settings';

interface ISupplyLinkPayment {}

const SupplyLinkPayment: React.FC<ISupplyLinkPayment> = () => {
	const {userIdQuery} = useSearchQueryContext();
	const [createCardSupplyPayment, {loading}] = useCreateSuccessSupplyPayment();

	const {enqueueSnackbar} = useSnackbar();

	const formik = useFormik({
		initialValues: {
			supplyCount: undefined,
			price: undefined,
		},
		onSubmit: ({supplyCount, price}) => {
			if (!supplyCount || !price || !userIdQuery) return undefined;

			return createCardSupplyPayment({
				variables: {
					input: {
						userId: userIdQuery,
						supplyCount,
						price,
					},
				},
			})
				.then(({data}) => {
					enqueueSnackbar(<>Платеж добавлен. Обновите историю</>, {variant: 'success'});
					console.log(data);
				})
				.catch((error) => {
					enqueueSnackbar(
						<>
							Ошибка: <br />${error.message}
						</>,
						{variant: 'error'},
					);
				});
		},
		validationSchema: object().shape({}),
	});

	return (
		<form onSubmit={formik.handleSubmit} style={{display: 'flex', justifyContent: 'center'}}>
			<Grid
				container
				direction={'column'}
				spacing={2}
				justifyContent={'center'}
				style={{width: 500}}
			>
				<Grid item container direction={'column'} spacing={2}>
					<Grid item>Количество автоброней</Grid>
					<Grid item>
						<TextField
							name="supplyCount"
							defaultValue={formik.values.supplyCount}
							variant={'filled'}
							type="number"
							placeholder="шт"
							onChange={formik.handleChange}
							fullWidth
							error={formik.touched.supplyCount && Boolean(formik.errors.supplyCount)}
							helperText={formik.touched.supplyCount && formik.errors.supplyCount}
						/>
					</Grid>
				</Grid>

				<Grid item container direction={'column'} spacing={2}>
					<Grid item>Цена</Grid>
					<Grid item>
						<TextField
							name="price"
							defaultValue={formik.values.price}
							placeholder="₽"
							type="number"
							variant={'filled'}
							onChange={formik.handleChange}
							fullWidth
							error={formik.touched.price && Boolean(formik.errors.price)}
							helperText={formik.touched.price && formik.errors.price}
						/>
					</Grid>
				</Grid>

				<Divider orientation={'horizontal'} style={{margin: '30px 0 10px'}} />

				<Grid item style={{textAlign: 'center'}}>
					<Button
						type={'submit'}
						disabled={!formik.values.price || !formik.values.supplyCount}
						loading={loading}
					>
						Добавить платеж [автоброни]
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default SupplyLinkPayment;
