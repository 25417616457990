import React, {useState} from 'react';
import {DatePicker} from '@mui/lab';
import {TextField} from '@mui/material';
import {endOfDay} from 'date-fns';
import {FormikProps} from 'formik';
import classNames from 'classnames';

import {TelegramUserSettingsInput} from '../../gqlApp/graphql';
import css from './SavedDateCalendar.pcss';

interface SavedInputProps {
	formik: FormikProps<TelegramUserSettingsInput>;
	name: string;
	modeEdit: boolean;
}

const SavedDateCalendar: React.FC<SavedInputProps> = (props) => {
	const {formik, name, modeEdit} = props;
	const [open, setOpen] = useState(false);

	return (
		<DatePicker
			mask="__.__.____ __:__"
			inputFormat="dd.MM.yyyy HH:mm"
			value={formik.values[name]}
			onChange={(newValue) => {
				formik.handleChange({
					target: {
						name,
						value: newValue ? endOfDay(newValue) : null,
					},
				});
			}}
			renderInput={(params) => (
				<TextField classes={{root: classNames({[css.root]: modeEdit})}} {...params} />
			)}
			open={open}
			onOpen={() => modeEdit && setOpen(true)}
			onClose={() => {
				setOpen(false);
				formik.handleSubmit();
			}}
		/>
	);
};

export default SavedDateCalendar;
