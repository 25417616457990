import React from 'react';

import {Header, LogoHeaderEnum, LoadingPage} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {useMeQuery} from 'api/users';
import css from './AuthLayoutRoute.pcss';

interface IAuthLayoutRoute {
	children?: React.ReactNode;
}

const AuthLayoutRoute: React.FC<IAuthLayoutRoute> = ({children}) => {
	const {loading} = useMeQuery();

	if (loading) {
		return <LoadingPage />;
	}

	return (
		<section className={css.container}>
			<Header onClickByLogo={() => undefined} logo={LogoHeaderEnum.BOT_LIMITS} authPage />
			{children}
		</section>
	);
};

export default AuthLayoutRoute;
