export enum MarketNameEnum {
	OZON = 'ozon',
	WILDBERRIES = 'wildberries',
	ALL = 'all',
}

class AppConsts {
	readonly GRAPHQL_URI =
		process.env.REACT_APP_GRAPHQL_URI && process.env.REACT_APP_GRAPHQL_URI.replace(/\/$/, '');

	readonly AUTH_TOKEN = 'auth_token';

	readonly MIN_PASSWORD_LENGTH = 8;

	readonly STORAGE = {
		DRAWER_IS_OPEN: () => localStorage.getItem('drawerIsOpen'),
	};

	readonly SEARCH_NAMES = {
		SEARCH: 'search',
		PAGE: 'page',
		ORDER_DIR: 'orderDir',
		USER: 'userId',
		DATE_RANGE_FROM: 'dateRangeFrom',
		DATE_RANGE_TO: 'dateRangeTo',
	};
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AppConsts();
