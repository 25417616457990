import appConsts from 'constants/appConsts';

export const getToken = () =>
	localStorage.getItem(appConsts.AUTH_TOKEN) || sessionStorage.getItem(appConsts.AUTH_TOKEN);

export const removeToken = () => {
	localStorage.removeItem(appConsts.AUTH_TOKEN);
	sessionStorage.removeItem(appConsts.AUTH_TOKEN);
};

export const setToken = (token: string, remember: boolean = true) => {
	removeToken();

	const storage = remember ? localStorage : sessionStorage;
	storage.setItem(appConsts.AUTH_TOKEN, token);
};
