import React from 'react';
import {Dialog, DialogContent, DialogProps, DialogTitle} from '@mui/material';
import {pick} from 'lodash';

import BroadcastForm from 'components/broadcasts/BroadcastForm';
import {useEditBroadcastMutation} from 'api/broadcasts';
import {Broadcast_BaseFragment, EditBroadcastInput} from '../../gqlApp/graphql';

interface IEditBroadcastModal {
	broadcast: Broadcast_BaseFragment;
	mode?: 'edit' | 'view';
}

const EditBroadcastModal: React.FC<IEditBroadcastModal & DialogProps> = ({
	broadcast,
	mode,
	...props
}) => {
	const [editBroadcast, {loading, error}] = useEditBroadcastMutation();

	return (
		<Dialog {...props} fullWidth maxWidth={'lg'}>
			<DialogTitle style={{textAlign: 'center', fontWeight: 'bold'}}>
				{mode === 'edit' ? 'Редактирование' : 'Режим просмотра'} рассылки{' '}
				<u>{broadcast.name}</u>
			</DialogTitle>

			<DialogContent>
				<BroadcastForm
					mode={mode}
					broadcast={broadcast}
					loading={loading}
					backgroundSlimCSS={true}
					onSubmit={(values) => {
						const variables = {
							input: pick(values, [
								'id',
								'name',
								'text',
								'deleteImage',
								'buttonUrl',
								'buttonText',
							]) as EditBroadcastInput,
							file: values.file,
						};

						if (values.file) {
							variables['file'] = values.file;
						}

						editBroadcast({variables})
							.then(() => props.onClose && props.onClose({}, 'backdropClick'))
							.catch(() => {});
					}}
				/>

				{error && (
					<div style={{color: 'red'}}>
						Ошибка: Если удалилось изображение, значит оно много весит или ...
						<br /> ...{error.message}
					</div>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default EditBroadcastModal;
