import {useQuery, useMutation} from '@apollo/client';
import {useSearchQueryContext} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import {useMemo} from 'react';
import {MarketNameEnum} from 'constants/appConsts';
import {DateRangeInput, PaymentStatus} from '../gqlApp/graphql';
import {graphql} from '../gqlApp';

//получить платежи пользователя по ID в Telegram,
// если Date Range = null, то возвращает всю историю платежей
const GET_USER_PAYMENTS_QUERY = graphql(`
	query GetUserPayments($input: TelegramUserPayments!) {
		getUserPayments(input: $input) {
			total
			items {
				createdAt
				amount
				orderId
				status
				type
				autoPayment
			}
		}
	}
`);

export const useGetUserPayments = ({
	dateRange,
	page,
	search: paymentStatus,
}: {
	dateRange: DateRangeInput;
	page: number;
	search?: PaymentStatus[];
}) => {
	const {userIdQuery} = useSearchQueryContext();

	const {data, loading, error, refetch} = useQuery(GET_USER_PAYMENTS_QUERY, {
		variables: {
			input: {
				userId: userIdQuery ?? 0,
				dateRange,
				page,
				paymentStatus,
			},
		},
	});

	return {
		data,
		loading,
		error,
		refetch,
	};
};

//получение списка платных автобронирований по WB
const GET_WB_PAID_SUPPLY_TRACKS_QUERY = graphql(`
	query GetWbPaidSupplyTracks($input: TelegramUserDateRange!) {
		getWbPaidSupplyTracks(input: $input) {
			total
			items {
				id
				createdAt
				enabled
				warehouseName
				supplyType
				coefficient
				supplyId
				supplyOrderText
				status
				resultStatus
				error
			}
		}
	}
`);

export const useGetWbPaidSupplyTracks = ({
	dateRange,
	page,
}: {
	dateRange: DateRangeInput;
	page: number;
}) => {
	const {userIdQuery} = useSearchQueryContext();

	const {data, loading, error, refetch} = useQuery(GET_WB_PAID_SUPPLY_TRACKS_QUERY, {
		variables: {
			input: {
				userId: userIdQuery ?? 0,
				dateRange,
				page,
			},
		},
	});

	return {
		data,
		loading,
		error,
		refetch,
	};
};

//получение списка поиска слотов по WB
const GET_WB_SUPPLY_TRACKS_QUERY = graphql(`
	query GetWbSupplyTracks($input: TelegramUserDateRange!) {
		getWbSupplyTracks(input: $input) {
			total
			items {
				id
				createdAt
				enabled
				warehouseName
				supplyType
				coefficient
				dates
				resultStatus
			}
		}
	}
`);

export const useGetWbSupplyTracks = ({
	dateRange,
	page,
}: {
	dateRange: DateRangeInput;
	page: number;
}) => {
	const {userIdQuery} = useSearchQueryContext();

	const {data, loading, error, refetch} = useQuery(GET_WB_SUPPLY_TRACKS_QUERY, {
		variables: {
			input: {
				userId: userIdQuery ?? 0,
				dateRange,
				page,
			},
		},
	});

	return {
		data,
		loading,
		error,
		refetch,
	};
};

//получение списка поиска слотов по Ozon
const GET_OZON_SUPPLY_TRACKS_QUERY = graphql(`
	query GetOzonSupplyTracks($input: TelegramUserDateRange!) {
		getOzonSupplyTracks(input: $input) {
			total
			items {
				id
				enabled
				supplyOrderId
				supplyOrderText
				resultStatus
			}
		}
	}
`);

export const useGetOzonSupplyTracks = ({
	dateRange,
	page,
}: {
	dateRange: DateRangeInput;
	page: number;
}) => {
	const {userIdQuery} = useSearchQueryContext();

	const {data, loading, error, refetch} = useQuery(GET_OZON_SUPPLY_TRACKS_QUERY, {
		variables: {
			input: {
				userId: userIdQuery ?? 0,
				dateRange,
				page,
			},
		},
	});

	return {
		data,
		loading,
		error,
		refetch,
	};
};

//изменение даты подписки или числа доступных автоброней
const SET_USER_SETTINGS_MUTATION = graphql(`
	mutation UserSetSettings($input: TelegramUserSettingsInput!) {
		userSetSettings(input: $input)
	}
`);

export const useSetUserSettings = () => useMutation(SET_USER_SETTINGS_MUTATION);

// отмена поиска слотов WB по id поставки
const DISABLE_WB_SUPPLY_MUTATION = graphql(`
	mutation DisableWbSupply($input: SupplySettingsInput!) {
		disableWbSupply(input: $input)
	}
`);

// отмена поиска слотов Ozon по id поставки
const DISABLE_OZON_SUPPLY_MUTATION = graphql(`
	mutation DisableOzonSupply($input: SupplySettingsInput!) {
		disableOzonSupply(input: $input)
	}
`);

export const useDisableSupply = ({marketName}: {marketName: string}) => {
	const [disableWb, {data: dataWb, loading: loadingWb, error: errorWb}] = useMutation(
		DISABLE_WB_SUPPLY_MUTATION,
	);
	const [disableOzon, {data: dataOzon, loading: loadingOzon, error: errorOzon}] = useMutation(
		DISABLE_OZON_SUPPLY_MUTATION,
	);

	return useMemo(() => {
		switch (marketName) {
			case MarketNameEnum.WILDBERRIES:
				return {
					loading: loadingWb,
					error: errorWb,
					data: dataWb,
					fn: disableWb,
				};

			case MarketNameEnum.OZON:
				return {
					loading: loadingOzon,
					error: errorOzon,
					data: dataOzon,
					fn: disableOzon,
				};

			default:
				return {
					data: false,
				};
		}
	}, [
		dataOzon,
		dataWb,
		disableOzon,
		disableWb,
		errorOzon,
		errorWb,
		loadingOzon,
		loadingWb,
		marketName,
	]);
};

//перевод автобронирования в неактивное (После его сможет перенести сам пользователь)
// - нужно убрать кнопку если enabled = false, а status = TIMEOUT или WBERROR
const DISABLE_WB_PAID_SUPPLY_MUTATION = graphql(`
	mutation DisableWbPaidSupply($input: SupplySettingsInput!) {
		disableWbPaidSupply(input: $input)
	}
`);

export const useDisableWbPaidSupply = () => useMutation(DISABLE_WB_PAID_SUPPLY_MUTATION);

//полная отмена автобронирования с возможностью возврата автоброни
// - нужно убрать кнопку если enabled = false, а status = ACTIVE
const DELETE_WB_PAID_SUPPLY_MUTATION = graphql(`
	mutation DeleteWbPaidSupply($input: SupplyDeleteInput!) {
		deleteWbPaidSupply(input: $input)
	}
`);

export const useDeleteWbPaidSupply = () => useMutation(DELETE_WB_PAID_SUPPLY_MUTATION);

//создает ссылку на оплату подписки
const CREATE_CARD_SUBSCRIPTION_MUTATION = graphql(`
	mutation CreateCardSubscriptionPayment($input: TelegramUserCreateSubscriptionPayment!) {
		createCardSubscriptionPayment(input: $input)
	}
`);

export const useCreateCardSubscriptionPayment = () =>
	useMutation(CREATE_CARD_SUBSCRIPTION_MUTATION);

//создает ссылку на оплату автоброней
const CREATE_CARD_SUPPLY_MUTATION = graphql(`
	mutation CreateCardSupplyPayment($input: TelegramUserCreateSupplyPayment!) {
		createCardSupplyPayment(input: $input)
	}
`);

export const useCreateCardSupplyPayment = () => useMutation(CREATE_CARD_SUPPLY_MUTATION);

//создает и сразу подтверждает платеж на подписки
const CREATE_SUCCESS_SUBSCRIPTION_PAYMENT_MUTATION = graphql(`
	mutation CreateSuccessSubscriptionPayment($input: TelegramUserCreateSubscriptionPayment!) {
		createSuccessSubscriptionPayment(input: $input)
	}
`);

export const useCreateSuccessSubscriptionPayment = () =>
	useMutation(CREATE_SUCCESS_SUBSCRIPTION_PAYMENT_MUTATION);

//создает и сразу подтверждает платеж на автоброни
const CREATE_SUCCESS_SUPPLY_PAYMENT_MUTATION = graphql(`
	mutation CreateSuccessSupplyPayment($input: TelegramUserCreateSupplyPayment!) {
		createSuccessSupplyPayment(input: $input)
	}
`);

export const useCreateSuccessSupplyPayment = () =>
	useMutation(CREATE_SUCCESS_SUPPLY_PAYMENT_MUTATION);
