import React, {useMemo} from 'react';

import css from 'components/auth/AuthWrapper.pcss';

interface IAuthWrapper {
	children: React.ReactNode;
	title: string;
	description?: React.ReactNode;
	error?: string;
}

const AuthWrapper: React.FC<IAuthWrapper> = ({children, title, description, error}) => {
	const handleError = useMemo(() => {
		switch (error) {
			default:
				return 'Произошла ошибка. Попробуйте еще раз';
		}
	}, [error]);

	return (
		<div className={css.container}>
			<div className={css.title}>{title}</div>

			<div className={css.description}>{description}</div>

			{children}

			{error && <div className={css.error}>{handleError}</div>}
		</div>
	);
};

export default AuthWrapper;
