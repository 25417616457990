import React, {useMemo} from 'react';
import {useQuery, useMutation} from '@apollo/client';
import {TooltipItem} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import format from 'date-fns/format';
import {sortBy} from 'lodash';
import ru from 'date-fns/locale/ru';
import {Theme, useMediaQuery} from '@mui/material';

import TooltipPayment from 'components/common/chart/TooltipPayment';
import {setToken} from 'utils/auth';
import {formatDate} from 'utils/chart';
import apolloClient from 'utils/apolloClient';
import {graphql} from '../gqlApp';
import {
	CountStatsResponse,
	LoginMutation,
	LoginMutationVariables,
	LogoutMutation,
	PasswordResetMutation,
	PasswordResetMutationVariables,
	PaymentsStatsResponseV3,
	PaymentStatV3QueryVariables,
	RegistrationsStatsResponse,
	SetNewPasswordMutation,
	SetNewPasswordMutationVariables,
	TelegramUsersActionsStatsQueryVariables,
	UniqueTelegramUsersActivityQueryVariables,
	UserRegistrationsStatQueryVariables,
} from '../gqlApp/graphql';

const LOGIN_MUTATION = graphql(`
	mutation Login($input: LoginInput!) {
		login(input: $input) {
			user {
				email
			}
			token
		}
	}
`);

export const loginMutation = (variables: LoginMutationVariables) =>
	apolloClient.mutate<LoginMutation, LoginMutationVariables>({
		variables,
		mutation: LOGIN_MUTATION,
		update(_, {data}) {
			if (data && data.login && data.login.token) {
				setToken(data.login.token);
			}
		},
	});

const REGISTRATION_MUTATION = graphql(`
	mutation Registration($input: RegisterInput!) {
		registration(input: $input)
	}
`);

export const useRegistrationMutation = () => useMutation(REGISTRATION_MUTATION);

const LOGOUT_MUTATION = graphql(`
	mutation Logout {
		logout
	}
`);

export const logoutMutation = () =>
	apolloClient.mutate<LogoutMutation, {}>({mutation: LOGOUT_MUTATION});

export const ME_QUERY = graphql(`
	query Me {
		me {
			id
			email
			name
		}
	}
`);

export const useMeQuery = () => useQuery(ME_QUERY);

const MAIN_STATS_STATS_QUERY = graphql(`
	query MainStats {
		mainStats {
			activeUserCount
			notActiveUserCount
			lastMonthActiveUserCount
			lastMonthActionCount
		}
	}
`);

export const useMainStatsQuery = () => useQuery(MAIN_STATS_STATS_QUERY);

const USER_REGISTRATION_STATS_QUERY = graphql(`
	query UserRegistrationsStat($input: DateRangeInput!) {
		userRegistrationsStat(input: $input) {
			groupedByTime
			items {
				date
				registrationCount
				unsubscribedCount
			}
		}
	}
`);

export const useUserRegistrationsStatQuery = (variables: UserRegistrationsStatQueryVariables) => {
	const {data, loading, error} = useQuery(USER_REGISTRATION_STATS_QUERY, {variables});

	return useMemo(() => {
		const makeData = (data?: RegistrationsStatsResponse) => {
			const sortedDataByDate = sortBy(data?.items, 'date');

			return {
				labels: sortedDataByDate?.map(({date}) =>
					format(date, formatDate(data), {locale: ru}),
				),
				datasets: [
					{
						label: 'Регистрации',
						unit: 'шт',
						type: 'line',
						color: 'rgb(33,150,243)',
						data: sortedDataByDate.map(({registrationCount}) =>
							Math.round(registrationCount),
						),
						tooltipReplace:
							sortedDataByDate?.map(({registrationCount, date}) => (
								<TooltipItem
									label={'Регистрации'}
									sum={null}
									count={registrationCount}
									groupBy={data?.groupedByTime}
									date={date}
								/>
							)) || [],
					},
					{
						label: 'Отписки',
						unit: 'шт',
						type: 'line',
						color: 'rgb(76,175,80)',
						data: sortedDataByDate.map(({unsubscribedCount}) =>
							Math.round(unsubscribedCount),
						),
						tooltipReplace:
							sortedDataByDate?.map(({unsubscribedCount}) => (
								<TooltipItem
									label={'Отписки'}
									sum={null}
									count={unsubscribedCount}
								/>
							)) || [],
					},
				],
			};
		};

		return {
			data: makeData(data?.userRegistrationsStat),
			loading,
			error,
		};
	}, [data?.userRegistrationsStat, error, loading]);
};

const TELEGRAM_USERS_ACTIONS_STATS_QUERY = graphql(`
	query TelegramUsersActionsStats($input: DateRangeInput!) {
		telegramUsersActionsStats(input: $input) {
			groupedByTime
			items {
				date
				count
			}
		}
	}
`);

export const useTelegramUsersActionsStatsQuery = (
	variables: TelegramUsersActionsStatsQueryVariables,
) => {
	const {data, loading, error} = useQuery(TELEGRAM_USERS_ACTIONS_STATS_QUERY, {variables});

	return useMemo(() => {
		const makeData = (data?: CountStatsResponse) => {
			const sortedDataByDate = sortBy(data?.items, 'date');

			return {
				labels: sortedDataByDate?.map(({date}) =>
					format(date, formatDate(data), {locale: ru}),
				),
				datasets: [
					{
						label: 'Действий',
						unit: 'шт',
						type: 'line',
						color: 'rgb(33,150,243)',
						data: sortedDataByDate.map(({count}) => Math.round(count)),
						tooltipReplace:
							sortedDataByDate?.map(({count, date}) => (
								<TooltipItem
									label={'Действий'}
									sum={null}
									count={count}
									groupBy={data?.groupedByTime}
									date={date}
								/>
							)) || [],
					},
				],
			};
		};

		return {
			data: makeData(data?.telegramUsersActionsStats),
			loading,
			error,
		};
	}, [data?.telegramUsersActionsStats, error, loading]);
};

const UNIQUE_TELEGRAM_USERS_ACTIVITY_QUERY = graphql(`
	query UniqueTelegramUsersActivity($input: DateRangeInput!) {
		uniqueTelegramUsersActivity(input: $input) {
			groupedByTime
			items {
				date
				count
			}
		}
	}
`);

export const useUniqueTelegramUsersActivityQuery = (
	variables: UniqueTelegramUsersActivityQueryVariables,
) => {
	const {data, loading, error} = useQuery(UNIQUE_TELEGRAM_USERS_ACTIVITY_QUERY, {variables});

	return useMemo(() => {
		const makeData = (data?: CountStatsResponse) => {
			const sortedDataByDate = sortBy(data?.items, 'date');

			return {
				labels: sortedDataByDate?.map(({date}) =>
					format(date, formatDate(data), {locale: ru}),
				),
				datasets: [
					{
						label: 'Уникальных пользователей',
						unit: 'шт',
						type: 'line',
						color: 'rgb(33,150,243)',
						data: sortedDataByDate.map(({count}) => Math.round(count)),
						tooltipReplace:
							sortedDataByDate?.map(({count, date}) => (
								<TooltipItem
									label={'Пользователей'}
									sum={null}
									count={count}
									groupBy={data?.groupedByTime}
									date={date}
								/>
							)) || [],
					},
				],
			};
		};

		return {
			data: makeData(data?.uniqueTelegramUsersActivity),
			loading,
			error,
		};
	}, [data?.uniqueTelegramUsersActivity, error, loading]);
};

const PASSWORD_RESET_MUTATION = graphql(`
	mutation PasswordReset($email: String!) {
		passwordReset(email: $email)
	}
`);

export const passwordResetMutation = (variables: PasswordResetMutationVariables) =>
	apolloClient.mutate<PasswordResetMutation, PasswordResetMutationVariables>({
		variables,
		mutation: PASSWORD_RESET_MUTATION,
	});

const SET_NEW_PASSWORD_MUTATION = graphql(`
	mutation SetNewPassword($input: NewPasswordInput!) {
		setNewPassword(input: $input)
	}
`);

export const setNewPasswordMutation = (variables: SetNewPasswordMutationVariables) =>
	apolloClient.mutate<SetNewPasswordMutation, SetNewPasswordMutationVariables>({
		variables,
		mutation: SET_NEW_PASSWORD_MUTATION,
	});

graphql(`
	fragment PAYMENTS on PaymentsStatTypeV3 {
		payments {
			product
			amount
			count
		}
		typeAmount
		typeCount
	}
`);

const PAYMENT_STAT_QUERY = graphql(`
	query PaymentStatV3($input: DateRangeInput!) {
		paymentsStats: paymentStatV3(input: $input) {
			items {
				date
				sumAmount
				sumCount
				SUPPLY {
					...PAYMENTS
				}
				SUBSCRIPTION {
					...PAYMENTS
				}
			}
			itemsSum {
				date
				sumAmount
				sumCount
				SUPPLY {
					...PAYMENTS
				}
				SUBSCRIPTION {
					...PAYMENTS
				}
			}
			supplySum
			subscriptionSum
			totalSum
			groupedByTime
		}
	}
`);

export const usePaymentStat = ({
	detailedMode,
	...variables
}: PaymentStatV3QueryVariables & {detailedMode: boolean}) => {
	const {data, loading, error} = useQuery(PAYMENT_STAT_QUERY, {variables});
	const isFromMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	return useMemo(() => {
		const makeData = (data?: PaymentsStatsResponseV3) => {
			const sortedDataByDate = sortBy(data?.items, 'date');

			return {
				labels: sortedDataByDate?.map(({date}) =>
					format(date, formatDate(data), {locale: ru}),
				),
				datasets: detailedMode
					? [
							{
								label: 'Автобронирования',
								unit: '₽',
								type: 'line',
								color: 'rgb(21,198,14)',
								data: sortedDataByDate.map(({SUPPLY}) =>
									Math.round(SUPPLY.typeAmount),
								),
								expandData: sortedDataByDate.map(({SUPPLY}) =>
									Math.round(SUPPLY.typeCount),
								),
								tooltipReplace:
									sortedDataByDate?.map(({date, SUPPLY}) => (
										<div style={{width: isFromMd ? undefined : 310}}>
											<TooltipItem
												label={'Автобронирования'}
												groupBy={data?.groupedByTime}
												date={date}
												sum={null}
												count={null}
											/>
											<br />
											<TooltipPayment payment={SUPPLY} />
										</div>
									)) || [],
							},
							{
								label: 'Подписки',
								unit: '₽',
								type: 'line',
								color: 'rgb(243,184,33)',
								data: sortedDataByDate.map(({SUBSCRIPTION}) =>
									Math.round(SUBSCRIPTION.typeAmount),
								),
								expandData: sortedDataByDate.map(({SUBSCRIPTION}) =>
									Math.round(SUBSCRIPTION.typeCount),
								),
								tooltipReplace:
									sortedDataByDate?.map(
										({sumAmount, sumCount, date, SUBSCRIPTION}) => (
											<div style={{width: isFromMd ? undefined : 310}}>
												<TooltipItem
													label={'Подписки'}
													groupBy={data?.groupedByTime}
													date={date}
													sum={null}
													count={null}
												/>
												<br />
												<TooltipPayment
													payment={SUBSCRIPTION}
													totalCount={sumCount}
													totalSum={sumAmount}
												/>
											</div>
										),
									) || [],
							},
					  ]
					: [
							{
								label: 'Общий итог',
								unit: '₽',
								type: 'line',
								color: 'rgb(66,143,90)',
								data: sortedDataByDate.map((props) => props.sumAmount),
								expandData: sortedDataByDate.map((props) => props.sumCount),
								tooltipReplace:
									sortedDataByDate?.map((props) => (
										<TooltipItem
											label={'Общий итог'}
											groupBy={data?.groupedByTime}
											date={props.date}
											count={props.sumCount}
											sum={props.sumAmount}
										/>
									)) || [],
							},
					  ],
			};
		};

		return {
			data: makeData(data?.paymentsStats),
			loading,
			error,
			stat: data?.paymentsStats,
		};
	}, [data?.paymentsStats, error, isFromMd, loading, detailedMode]);
};
