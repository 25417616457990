import React from 'react';
import {Link} from 'react-router-dom';
import {FormikHelpers, useFormik} from 'formik';
import {object, string, SchemaOf} from 'yup';
import {TextField} from '@mui/material';
import {useNavigate} from 'react-router';

import ButtonLoader from 'components/common/ui/ButtonLoader';
import AuthWrapper from 'components/auth/AuthWrapper';
import {loginMutation} from 'api/users';
import routeUrls from 'constants/routeUrls';
import appConsts from 'constants/appConsts';
import {LoginInput} from '../../gqlApp/graphql';
import css from './AuthPage.pcss';

const initialValues: LoginInput = {email: '', password: ''};

const validationSchema: SchemaOf<LoginInput> = object().shape({
	email: string().email().required(),

	password: string().min(appConsts.MIN_PASSWORD_LENGTH).required(),
});

const LoginPage: React.FC = () => {
	const navigate = useNavigate();

	const onSubmit = (values: LoginInput, actions: FormikHelpers<LoginInput>) =>
		loginMutation({
			input: {
				email: values.email,
				password: values.password,
			},
		})
			.then(() => {
				navigate(routeUrls.APP_DASHBOARD);
			})
			.catch((err) => {
				err.graphQLErrors.forEach((error: any) => {
					switch (error?.message) {
						case 'PASSWORD_DONT_MATCH':
							return actions.setErrors({password: 'Неправильный пароль'});

						case 'USER_NOT_ACTIVE':
							return actions.setErrors({email: 'Пользователь не является админом'});

						case 'USER_WITH_EMAIL_NOT_EXIST':
							return actions.setErrors({email: 'Пользователь не найден'});

						default:
							return error?.message;
					}
				});
			});

	const formik = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
	});

	return (
		<AuthWrapper
			title={'Вход'}
			description={
				<>
					Используйте свою почту и пароль для входа в аккаунт или{' '}
					<Link to={routeUrls.AUTH_REGISTRATION}>зарегистрируйстесь</Link>
				</>
			}
		>
			<form onSubmit={formik.handleSubmit}>
				<TextField
					name="email"
					label="Email"
					fullWidth
					margin="normal"
					value={formik.values.email}
					onChange={formik.handleChange}
					error={formik.touched.email && Boolean(formik.errors.email)}
					helperText={formik.touched.email && formik.errors.email}
				/>

				<TextField
					name="password"
					label="Пароль"
					type="password"
					fullWidth
					margin="normal"
					value={formik.values.password}
					onChange={formik.handleChange}
					error={formik.touched.password && Boolean(formik.errors.password)}
					helperText={formik.touched.password && formik.errors.password}
				/>

				<ButtonLoader
					loading={formik.isSubmitting}
					type="submit"
					className={css.submit}
					variant="outlined"
					color="primary"
					fullWidth
					size="large"
				>
					Войти
				</ButtonLoader>
			</form>

			<div className={css.forgotPassword}>
				Забыли пароль? <Link to={routeUrls.AUTH_FORGOT_PASSWORD}>Восстановить</Link>
			</div>
		</AuthWrapper>
	);
};

export default LoginPage;
