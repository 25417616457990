import React, {useState} from 'react';
import {
	Alert,
	Autocomplete,
	Chip,
	Dialog,
	DialogContent,
	DialogProps,
	DialogTitle,
	TextField,
	Tooltip,
} from '@mui/material';

import ButtonLoader from 'components/common/ui/ButtonLoader';
import {useTestBroadcastMutation} from 'api/broadcasts';
import {EditBroadcastInput} from '../../gqlApp/graphql';

interface IEditBroadcastModal {
	broadcast: EditBroadcastInput;
}

const EditBroadcastModal: React.FC<IEditBroadcastModal & DialogProps> = ({broadcast, ...props}) => {
	const [testBroadcast, {data, loading, error}] = useTestBroadcastMutation();
	const [usernames, setUsernames] = useState<string[]>([]);

	return (
		<Dialog {...props} fullWidth maxWidth={'sm'}>
			<DialogTitle>Тест рассылки #{broadcast.name}</DialogTitle>

			<DialogContent>
				<br />

				<Autocomplete
					multiple
					options={usernames}
					freeSolo
					renderTags={(value: readonly string[], getTagProps) =>
						value.map((option: string, index: number) => (
							<Chip variant="outlined" label={option} {...getTagProps({index})} />
						))
					}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label="Имена пользователей"
							placeholder="Например: UserName"
						/>
					)}
					onChange={(_e, newValue: string[]) => {
						setUsernames(newValue);
					}}
				/>

				{error && (
					<>
						<br />
						<Alert severity="error">{error.message}</Alert>
					</>
				)}

				{data && (
					<>
						<br />
						<Alert severity="success">Тест прошёл</Alert>
					</>
				)}

				<br />

				<Tooltip
					title={
						usernames.length === 0
							? 'Нажмите Enter, чтобы добавить имя пользователя в строку'
							: ''
					}
				>
					<div>
						<ButtonLoader
							loading={loading}
							variant="contained"
							color="primary"
							size="large"
							disabled={usernames.length === 0}
							onClick={() =>
								testBroadcast({
									variables: {input: {id: broadcast.id, usernames}},
								}).catch(() => {})
							}
						>
							Запустить тест
						</ButtonLoader>
					</div>
				</Tooltip>
			</DialogContent>
		</Dialog>
	);
};

export default EditBroadcastModal;
