import React from 'react';
import Button, {ButtonProps} from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import cn from 'classnames';

import css from './ButtonLoader.pcss';

interface IButtonLoaderProps extends ButtonProps {
	loading?: boolean;
	wrapperClassName?: string;
}

const progressSize = 20;

const ButtonLoader: React.FunctionComponent<IButtonLoaderProps> = ({loading, ...props}) => {
	const {wrapperClassName, disabled, ...buttonProps} = props;

	return (
		<div className={cn(css.wrapper, wrapperClassName)}>
			<Button {...buttonProps} disabled={disabled || loading} />

			{loading && <CircularProgress size={progressSize} className={css.buttonProgress} />}
		</div>
	);
};

ButtonLoader.defaultProps = {
	wrapperClassName: '',
};

export default ButtonLoader;
