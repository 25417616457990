import {ceil, floor} from 'lodash';

class ChartMath {
	ceil = (num: number) => ceil(num, -`${num}`.length + 1);
	floor = (num: number) => floor(num, -`${num}`.length + 1);
}

export const chartMath = new ChartMath();

export const formatDate = (data: any) =>
	data?.groupedByTime === 'HOUR' ? 'HH:mm' : 'dd.MM.yy EEEEEE';
