import React, {useMemo} from 'react';
import {Modal} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {DialogContent, Grid, Tab, Tabs} from '@mui/material';

import {ActionType} from 'components/settings_user/menuActions/MenuActions';
import SubscriptionPaymentForm from 'components/settings_user/menuActions/forms/SubscriptionPaymentForm';
import SupplyPaymentForm from 'components/settings_user/menuActions/forms/SupplyPaymentForm';
import css from './CreatePaymentLinkModal.pcss';

interface ICreatePaymentLinkModal {
	open?: ActionType;
	onClose: () => void;
}

const AddPaymentModal: React.FC<ICreatePaymentLinkModal> = ({open, onClose}) => {
	const [value, setValue] = React.useState(0);

	const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const formPayment = useMemo(() => {
		switch (value) {
			default:
			case 0:
				return <SubscriptionPaymentForm />;
			case 1:
				return <SupplyPaymentForm />;
		}
	}, [value]);

	return (
		<Modal open={open === ActionType.AddPayment} onClose={onClose}>
			<DialogContent className={css.dialogContent}>
				<Grid item className={css.title}>
					Добавить совершенный платеж
				</Grid>
				<Tabs
					value={value}
					onChange={handleChange}
					classes={{root: css.tabs, flexContainer: css.flexContainer}}
				>
					<Tab
						label="Подписка"
						onChange={() => setValue(0)}
						disableRipple
						classes={{root: css.tab}}
					/>
					<Tab
						label="Пакет автоброней"
						onChange={() => setValue(1)}
						disableRipple
						classes={{root: css.tab}}
					/>
				</Tabs>
				<div className={css.formPayment}>{formPayment}</div>
			</DialogContent>
		</Modal>
	);
};

export default AddPaymentModal;
