import React from 'react';
import {Navigate} from 'react-router-dom';
import {Theme, useMediaQuery} from '@mui/material';
import {SnackbarProvider} from 'notistack';
import {
	LoadingPage,
	SearchQueryContextProvider,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import {LayoutContextProvider} from 'layouts/LayoutContext';
import {useMeQuery} from 'api/users';
import routeUrls from 'constants/routeUrls';

interface IClientLayoutRoute {
	children?: React.ReactNode;
}

const ClientLayoutRoute: React.FC<IClientLayoutRoute> = ({children}) => {
	const isFromMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	const {loading, data} = useMeQuery();

	if (loading) {
		return <LoadingPage />;
	}

	if (!data?.me) {
		return <Navigate to={routeUrls.AUTH_LOGIN} replace />;
	}

	return (
		<LayoutContextProvider>
			<SearchQueryContextProvider>
				<SnackbarProvider
					maxSnack={3}
					anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
					SnackbarProps={{style: {minWidth: !isFromMd ? 400 : undefined}}}
					autoHideDuration={10000}
				>
					<section>{children}</section>
				</SnackbarProvider>
			</SearchQueryContextProvider>
		</LayoutContextProvider>
	);
};

export default ClientLayoutRoute;
