import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {FormikHelpers, useFormik} from 'formik';
import {object, string, SchemaOf} from 'yup';
import {TextField} from '@mui/material';
import {isNil} from 'lodash';
import clsx from 'classnames';

import AuthWrapper from 'components/auth/AuthWrapper';
import ButtonLoader from 'components/common/ui/ButtonLoader';
import {useRegistrationMutation} from 'api/users';
import routeUrls from 'constants/routeUrls';
import appConsts from 'constants/appConsts';
import {RegisterInput} from '../../gqlApp/graphql';
import css from './AuthPage.pcss';

const initialValues: RegisterInput = {name: '', email: '', password: ''};

const validationSchema: SchemaOf<RegisterInput> = object().shape({
	name: string().min(3, 'Введите не менее 3 символов').trim().required('Введите имя'),

	email: string().email().required('Введите емейл'),

	password: string()
		.trim()
		.min(appConsts.MIN_PASSWORD_LENGTH, 'Пароль должен быть не менее 8 символов')
		.required('Введите пароль'),
});

const RegistrationPage: React.FC = () => {
	const [registration, {data}] = useRegistrationMutation();

	const onSubmit = (values: RegisterInput, actions: FormikHelpers<RegisterInput>) =>
		registration({
			variables: {
				input: {
					name: values.name,
					email: values.email,
					password: values.password,
				},
			},
		}).catch((err) => {
			err.graphQLErrors.forEach((error: any) => {
				if (error.extensions?.code === 'FORBIDDEN') {
					actions.setErrors({email: error.message});

					return;
				}

				if (error.message === 'EMAIL_EXIST_ERROR_MESSAGE') {
					actions.setErrors({email: 'Пользователь уже зарегистрирован'});

					return;
				}

				actions.setErrors({
					[Object.keys(error.extensions?.exception || {})[0]]: error.message,
				});
			});
		});

	const formik = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
	});

	const message = useMemo(() => {
		if (isNil(data)) {
			return null;
		}

		return (
			<div className={clsx(css.message, {[css.error]: !data})}>
				{data ? (
					<>
						Вы успешно зарегистрированы. <br /> Для входа вам необходимо получить права
						админа
					</>
				) : (
					'В регистрации отказано'
				)}
			</div>
		);
	}, [data]);

	return (
		<AuthWrapper
			title={'Регистрация'}
			description={
				<>
					Заполните все поля для регистрации или{' '}
					<Link to={routeUrls.AUTH_LOGIN}>авторизуйтесь</Link>
				</>
			}
		>
			<div className={css.form}>
				<form onSubmit={formik.handleSubmit} className={css.form}>
					<TextField
						name="name"
						label="Имя"
						fullWidth
						margin="normal"
						value={formik.values.name}
						onChange={formik.handleChange}
						error={formik.touched.name && Boolean(formik.errors.name)}
						helperText={formik.touched.name && formik.errors.name}
					/>

					<TextField
						name="email"
						label="Email"
						type="email"
						fullWidth
						margin="normal"
						value={formik.values.email}
						onChange={formik.handleChange}
						error={formik.touched.email && Boolean(formik.errors.email)}
						helperText={formik.touched.email && formik.errors.email}
					/>

					<TextField
						name="password"
						label="Пароль"
						type="password"
						fullWidth
						margin="normal"
						value={formik.values.password}
						onChange={formik.handleChange}
						error={formik.touched.password && Boolean(formik.errors.password)}
						helperText={formik.touched.password && formik.errors.password}
					/>

					{message}

					<ButtonLoader
						loading={formik.isSubmitting}
						type="submit"
						className={css.submit}
						variant="outlined"
						color="primary"
						fullWidth
						size="large"
					>
						Зарегистрироваться
					</ButtonLoader>
				</form>
			</div>
		</AuthWrapper>
	);
};

export default RegistrationPage;
