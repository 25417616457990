import React from 'react';
import {useDisableWbPaidSupply} from 'api/user_settings';
import {Switch} from '@mui/material';
import {useSnackbar} from 'notistack';

import {useConfirmationModalContext} from 'components/common/ui/Confirm';

interface SwitchDisableWbPaidSupplyProps {
	initialValueSwitch: boolean;
	id: number;
	isShow?: boolean;
	refetch: () => void;
}

const SwitchDisableWbPaidSupply: React.FC<SwitchDisableWbPaidSupplyProps> = ({
	id,
	refetch,
	initialValueSwitch,
	isShow,
}) => {
	const [disablePaidSupply] = useDisableWbPaidSupply();

	const {enqueueSnackbar} = useSnackbar();

	const {showConfirmation} = useConfirmationModalContext();

	const handleClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();

		(await showConfirmation({
			el: e.currentTarget,
			message: (
				<>
					Точно отключить <br /> автобронирование?
				</>
			),
		})) &&
			disablePaidSupply &&
			disablePaidSupply({
				variables: {
					input: {
						supplyId: id,
					},
				},
			})
				.then(() => refetch())
				.catch(() => {
					enqueueSnackbar('Ошибка ', {variant: 'error'});
				});
	};

	return (
		<Switch checked={initialValueSwitch} onClick={(e) => handleClick(e)} disabled={!isShow} />
	);
};

export default SwitchDisableWbPaidSupply;
