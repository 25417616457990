import React from 'react';
import {useNavigate} from 'react-router';
import pick from 'lodash/pick';

import BroadcastForm from 'components/broadcasts/BroadcastForm';
import AppWrapper from 'components/common/AppWrapper';
import {useCreateBroadcastMutation} from 'api/broadcasts';
import routeUrls from 'constants/routeUrls';
import apolloClient from 'utils/apolloClient';
import {CreateBroadcastInput} from '../../gqlApp/graphql';

const CreateBroadcastPage: React.FC = () => {
	const navigate = useNavigate();

	const [createBroadcast, {loading, error}] = useCreateBroadcastMutation();

	return (
		<AppWrapper title="Создать рассылку">
			<BroadcastForm
				mode={'edit'}
				onSubmit={(values) => {
					createBroadcast({
						variables: {
							input: pick(values, [
								'name',
								'text',
								'buttonUrl',
								'buttonText',
							]) as CreateBroadcastInput,
							file: values.file,
						},
					})
						.then(async () => {
							await apolloClient.clearStore();

							navigate(routeUrls.APP_BROADCAST_LIST);
						})
						.catch(() => {});
				}}
				loading={loading}
			/>

			{error && (
				<div style={{color: 'red'}}>
					Ошибка: Если удалилось изображение, значит оно много весит или ...
					<br /> ...{error.message}
				</div>
			)}
		</AppWrapper>
	);
};

export default CreateBroadcastPage;
