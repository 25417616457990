import React, {useCallback, useMemo, useState} from 'react';
import {FormikHelpers, useFormik} from 'formik';
import {object, string} from 'yup';
import {GraphQLError} from 'graphql';
import {Grid, TextField} from '@mui/material';
import {useNavigate} from 'react-router';

import ButtonLoader from 'components/common/ui/ButtonLoader';
import AuthWrapper from 'components/auth/AuthWrapper';
import {passwordResetMutation} from 'api/users';
import routeUrls from 'constants/routeUrls';
import {PasswordResetMutationVariables} from '../../gqlApp/graphql';

const initialValues: PasswordResetMutationVariables = {email: ''};

const validationSchema = object().shape({
	email: string()
		.email('Укажите корректный адрес электронной почты')
		.required('Укажите адрес электронной почты'),
});

const ForgotPasswordPage: React.FC = () => {
	const navigate = useNavigate();

	const [success, setSuccess] = useState(false);

	const onSubmit = useCallback(
		(
			{email}: PasswordResetMutationVariables,
			actions: FormikHelpers<PasswordResetMutationVariables>,
		) =>
			passwordResetMutation({email})
				.then(() => setSuccess(true))
				.catch((err) => {
					err.graphQLErrors.forEach((error: GraphQLError) => {
						switch (error.message) {
							case 'EMAIL_EXIST_ERROR_MESSAGE':
								actions.setErrors({
									email: 'Пользователя с таким email не существует',
								});

								break;

							default:
								actions.setErrors({
									email: 'Ошибка. Обратитесь к разработчикам',
								});
						}

						actions.setSubmitting(false);
					});
				}),
		[],
	);

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
	});

	const message = useMemo(() => {
		if (!success) {
			return (
				<form onSubmit={formik.handleSubmit}>
					<Grid container spacing={2} direction={'column'}>
						<Grid item xs>
							Введите ваш Email
						</Grid>

						<Grid item xs>
							<TextField
								onChange={formik.handleChange}
								disabled={success}
								fullWidth
								name="email"
								placeholder="Email"
								value={formik.values.email}
								autoFocus
								error={!!formik.errors.email}
								helperText={formik.errors?.email}
							/>
						</Grid>

						<Grid item xs>
							<ButtonLoader
								loading={formik.isSubmitting}
								disabled={success}
								type="submit"
								color="primary"
								variant="outlined"
								size="large"
								fullWidth
							>
								Продолжить
							</ButtonLoader>
						</Grid>
					</Grid>
				</form>
			);
		}

		return (
			<Grid container direction={'column'} spacing={1}>
				<Grid item xs>
					<p>
						Мы отправили <b>ссылку для восстановления</b> в письме
						<br />
						Нажмите её, чтобы продолжить
					</p>
				</Grid>
				<Grid item xs>
					<ButtonLoader
						loading={formik.isSubmitting}
						onClick={() => navigate(routeUrls.AUTH_LOGIN)}
						variant="outlined"
						color="primary"
						fullWidth
						size="large"
					>
						На главную
					</ButtonLoader>
				</Grid>
			</Grid>
		);
	}, [formik, navigate, success]);

	return <AuthWrapper title={'Восстановление'}>{message}</AuthWrapper>;
};

export default ForgotPasswordPage;
