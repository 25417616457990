import React from 'react';

import UtmSourceTable from 'components/utm_source/UtmSourceTable';
import AppWrapper from 'components/common/AppWrapper';

const UtmSourcePage: React.FC = () => (
	<AppWrapper title={'UTM - метки'}>
		<UtmSourceTable />
	</AppWrapper>
);

export default UtmSourcePage;
