import React, {createContext, useState} from 'react';

import appConsts from 'constants/appConsts';

interface ILayoutContext {
	drawerIsOpen: boolean;
	toggleDrawer: (isOpen: boolean, noSave?: boolean) => void;
}

export const LayoutContext = createContext<ILayoutContext>({
	drawerIsOpen: appConsts.STORAGE.DRAWER_IS_OPEN() === 'true',
	toggleDrawer: () => {},
});

interface ILayoutContextProvider {
	children?: React.ReactNode;
}

export const LayoutContextProvider: React.FC<ILayoutContextProvider> = ({children}) => {
	const [drawerIsOpen, cToggleDrawer] = useState(localStorage.getItem('drawerIsOpen') === 'true');

	const toggleDrawer = (open: boolean, noSave = true) => {
		if (!noSave) {
			localStorage.setItem('drawerIsOpen', `${open}`);
		}

		cToggleDrawer(open);
	};

	return (
		<LayoutContext.Provider
			value={{
				drawerIsOpen,
				toggleDrawer,
			}}
		>
			{children}
		</LayoutContext.Provider>
	);
};

export const useLayoutContext = () => React.useContext(LayoutContext);
