import React from 'react';
import {subDays} from 'date-fns';
import {DateRange} from '@mui/lab/DateRangePicker';
import {
	ContentBlock,
	DateRangeTemplate,
	SelectRangeDate,
	VisualChart,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import {useUserRegistrationsStatQuery} from 'api/users';

const NewAndDeletedUsersChart: React.FC = () => {
	const [dateRangeValue, setDateRangeValue] = React.useState<DateRange<Date>>([
		subDays(new Date(), 30),
		new Date(),
	]);

	const onDateRangeChange = (newValue: DateRange<Date>) => {
		setDateRangeValue(newValue);
	};

	const {data, loading, error} = useUserRegistrationsStatQuery({
		input: {
			dateFrom: dateRangeValue[0]?.getTime(),
			dateTo: dateRangeValue[1]?.getTime(),
		},
	});

	return (
		<ContentBlock
			label="Статистика пользователей"
			rightHeaderBlock={
				<SelectRangeDate
					onChange={onDateRangeChange}
					dateRangeTemplate={DateRangeTemplate.month}
				/>
			}
			loading={loading}
			error={!!error}
		>
			<VisualChart
				datasets={data.datasets}
				labels={data.labels}
				loading={loading}
				hideTotal
			/>
		</ContentBlock>
	);
};

export default NewAndDeletedUsersChart;
