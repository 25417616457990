import {createTheme} from '@mui/material/styles';

// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme({
	palette: {},
	typography: {},
	shape: {},
	components: {},
	mixins: {},
});

theme = {
	...theme,
	components: {},
};

export default theme;
