import React, {useMemo} from 'react';
import {subDays} from 'date-fns';
import {DateRange} from '@mui/lab/DateRangePicker';
import {
	ContentBlock,
	DateRangeTemplate,
	SelectRangeDate,
	SwitchKit,
	VisualChart,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {Grid, Link, Tooltip} from '@mui/material';

import {usePaymentStat} from 'api/users';
import TooltipPayment from 'components/common/chart/TooltipPayment';
import css from './PaymentChart.pcss';

const PaymentChart: React.FC = () => {
	const [detailedMode, setDetailedMode] = React.useState<boolean>(false);

	const [dateRangeValue, setDateRangeValue] = React.useState<DateRange<Date>>([
		subDays(new Date(), 30),
		new Date(),
	]);

	const onDateRangeChange = (newValue: DateRange<Date>) => {
		setDateRangeValue(newValue);
	};

	const {data, stat, loading} = usePaymentStat({
		input: {
			dateFrom: dateRangeValue[0]?.getTime(),
			dateTo: dateRangeValue[1]?.getTime(),
		},
		detailedMode,
	});

	const moreDetailsStat = useMemo(
		() => (
			<div style={{padding: 10}}>
				<TooltipPayment payment={stat?.itemsSum.SUPPLY} label={'Автобронирования'} />
				<TooltipPayment payment={stat?.itemsSum.SUBSCRIPTION} label={'Подписки'} />
			</div>
		),
		[stat?.itemsSum.SUBSCRIPTION, stat?.itemsSum.SUPPLY],
	);

	const totalBlock = useMemo(
		() => (
			<Grid container direction={'column'} className={css.stat}>
				{/*<Grid>*/}
				{/*	Автобронирования —{' '}*/}
				{/*	{sumBy(stat?.itemsSum.SUPPLY.payments, 'count').toLocaleString()} оплат{' '}*/}
				{/*	<b>на {stat?.supplySum.toLocaleString()} ₽</b>*/}
				{/*</Grid>*/}
				{/*<Grid>*/}
				{/*	Подписки —{' '}*/}
				{/*	{sumBy(stat?.itemsSum.SUBSCRIPTION.payments, 'count').toLocaleString()} оплат{' '}*/}
				{/*	<b>на {stat?.subscriptionSum.toLocaleString()} ₽</b>*/}
				{/*</Grid>*/}
				<Grid item>
					<Tooltip title={moreDetailsStat} classes={{tooltip: css.tooltip}}>
						<Link className={css.link}>Подробнее</Link>
					</Tooltip>
				</Grid>
			</Grid>
		),
		[moreDetailsStat],
	);

	return (
		<ContentBlock
			label={
				<>
					<span style={{marginRight: 20}}>Платежи</span>
					<SwitchKit
						label={'Детализация'}
						onClick={() => setDetailedMode(!detailedMode)}
					/>
				</>
			}
			rightHeaderBlock={
				<SelectRangeDate
					onChange={onDateRangeChange}
					dateRangeTemplate={DateRangeTemplate.month}
				/>
			}
			loading={loading}
			children={
				<VisualChart
					labels={data.labels}
					datasets={data.datasets}
					loading={loading}
					hideLegendFilter={!detailedMode}
					replaceTotal={detailedMode ? totalBlock : undefined}
					hideTotal={!detailedMode}
				/>
			}
		/>
	);
};

export default PaymentChart;
