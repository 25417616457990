import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import clsx from 'classnames';

import css from './Dropzone.pcss';

interface IDropzone {
	onDrop: (files: File[]) => void;
}

const Dropzone: React.FC<IDropzone> = ({onDrop}) => {
	const [files, setFiles] = useState<File[]>([]);
	const {getRootProps, getInputProps, isDragAccept, isDragActive, isFocused, isDragReject} =
		useDropzone({
			accept: 'image/*',
			maxFiles: 1,
			onDrop: (dFiles) => setFiles([...files, ...dFiles]),
		});

	useEffect(() => {
		onDrop(files);

		// eslint-disable-next-line
	}, [files]);

	return (
		<>
			<div
				{...getRootProps({
					className: clsx(css.dropzone, {
						[css.isDragAccept]: isDragAccept,
						[css.isDragActive]: isDragActive,
						[css.isFocused]: isFocused,
						[css.isDragReject]: isDragReject,
					}),
				})}
			>
				<input {...getInputProps()} />
				<p>Перенесите картинку или кликните сюда</p>
			</div>
		</>
	);
};

export default Dropzone;
