class RouteUrls {
	APP_DASHBOARD = '/';

	APP_CREATE_BROADCAST = '/create-broadcast';

	APP_BROADCAST_LIST = '/broadcast-list';

	APP_TELEGRAM_USERS_LIST = '/telegram-users-list';

	AUTH_LOGIN = '/auth/login';
	AUTH_REGISTRATION = '/auth/registration';
	AUTH_FORGOT_PASSWORD = '/auth/forgot-password';
	AUTH_PASS_RECOVERY = '/auth/passrecovery/:code';
	APP_PAY_LIST = '/pay-list';
	APP_UTM_SOURCE = '/utm-source';
	APP_SETTINGS_USER = '/settings-user';

	BOT_LIMITS_TELEGRAM = 'https://t.me/mp_helpbot';

	ADMINS = {
		SHOPSTAT: 'https://sp-admin.shopstat.ru/',
		OTVETO_RU: 'https://fb-bot-admin.shopstat.ru/',
	};
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RouteUrls();
