import React from 'react';

import TelegramUsersTable from 'components/users/TelegramUsersTable';
import AppWrapper from 'components/common/AppWrapper';

const TelegramUsersPage: React.FC = () => (
	<AppWrapper title="Список пользователей">
		<TelegramUsersTable />
	</AppWrapper>
);

export default TelegramUsersPage;
