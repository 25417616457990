import React from 'react';

const TypeCellFormat = ({value}: {value: string}) => {
	switch (value) {
		case 'SUBSCRIPTION':
			return <>Подписка</>;
		case 'SUPPLY_PACKAGE':
			return <>Пакет автоброней</>;
		case 'SUPPLY':
			return <>Автоброни</>;

		default:
			return <>''</>;
	}
};

export default TypeCellFormat;
