import React from 'react';
import {BrowserRouter, Routes as RoutesRRM, Route} from 'react-router-dom';
import {Error404} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import AuthLayoutRoute from 'layouts/AuthLayoutRoute';
import ClientLayoutRoute from 'layouts/ClientLayoutRoute';
import LoginPage from 'components/auth/LoginPage';
import DashboardPage from 'components/pages/DashboardPage';
import CreateBroadcastPage from 'components/pages/CreateBroadcastPage';
import BroadcastsPage from 'components/pages/BroadcastsPage';
import RegistrationPage from 'components/auth/RegistrationPage';
import TelegramUsersPage from 'components/pages/TelegramUsersPage';
import ForgotPasswordPage from 'components/auth/ForgotPasswordPage';
import PassRecoveryPage from 'components/auth/PassRecoveryPage';
import PayPage from 'components/pages/PayPage';
import SettingsUserPage from 'components/pages/SettingsUserPage';
import UtmSourcePage from 'components/pages/UtmSourcePage';
import routeUrls from 'constants/routeUrls';

const Routes: React.FC = () => (
	<BrowserRouter>
		<RoutesRRM>
			{/* Авторизация */}
			<Route
				path={routeUrls.AUTH_LOGIN}
				element={<AuthLayoutRoute children={<LoginPage />} />}
			/>
			<Route
				path={routeUrls.AUTH_REGISTRATION}
				element={<AuthLayoutRoute children={<RegistrationPage />} />}
			/>
			<Route
				path={routeUrls.AUTH_FORGOT_PASSWORD}
				element={<AuthLayoutRoute children={<ForgotPasswordPage />} />}
			/>
			<Route
				path={routeUrls.AUTH_PASS_RECOVERY}
				element={<AuthLayoutRoute children={<PassRecoveryPage />} />}
			/>

			{/* Админка */}
			<Route
				path={routeUrls.APP_DASHBOARD}
				element={<ClientLayoutRoute children={<DashboardPage />} />}
			/>
			<Route
				path={routeUrls.APP_CREATE_BROADCAST}
				element={<ClientLayoutRoute children={<CreateBroadcastPage />} />}
			/>
			<Route
				path={routeUrls.APP_BROADCAST_LIST}
				element={<ClientLayoutRoute children={<BroadcastsPage />} />}
			/>
			<Route
				path={routeUrls.APP_TELEGRAM_USERS_LIST}
				element={<ClientLayoutRoute children={<TelegramUsersPage />} />}
			/>
			<Route
				path={routeUrls.APP_PAY_LIST}
				element={<ClientLayoutRoute children={<PayPage />} />}
			/>
			<Route
				path={routeUrls.APP_SETTINGS_USER}
				element={<ClientLayoutRoute children={<SettingsUserPage />} />}
			/>
			<Route
				path={routeUrls.APP_UTM_SOURCE}
				element={<ClientLayoutRoute children={<UtmSourcePage />} />}
			/>

			<Route
				path="*"
				element={<AuthLayoutRoute children={<Error404 className={undefined} />} />}
			/>
		</RoutesRRM>
	</BrowserRouter>
);

export default Routes;
