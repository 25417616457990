import React, {useEffect, useMemo, useState} from 'react';
import {
	ContentBlock,
	Tooltip,
	useSearchQueryContext,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {Grid, Link} from '@mui/material';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';

import SavedSwitch from 'components/settings_user/SavedSwitch';
import SavedDateCalendar from 'components/settings_user/SavedDateCalendar';
import {useSetUserSettings} from 'api/user_settings';
import {useGetUserData} from 'api/telegram_users';
import css from './GeneralInformationAboutUser.pcss';
import {TelegramUserSettingsInput} from '../../gqlApp/graphql';
import {ReactComponent as IconToken} from '../common/icons/IconAmount.svg';

const GeneralInformationAboutUser: React.FC = () => {
	const {data, error, loading} = useGetUserData();

	const {userIdQuery} = useSearchQueryContext();
	const {enqueueSnackbar} = useSnackbar();

	const [updateSettings, {loading: loadingSettings}] = useSetUserSettings();
	const [modeEdit, setModeEdit] = useState<boolean>(false);

	const initialValues: TelegramUserSettingsInput = useMemo(
		() => ({
			activeToDate: data?.getUserData.activeToDate,
			autoPayment: data?.getUserData.autoPayment,
			freeSupplyCount: data?.getUserData.freeSupplyCount,
			userId: userIdQuery ?? 0,
		}),
		[
			data?.getUserData.freeSupplyCount,
			data?.getUserData.activeToDate,
			data?.getUserData.autoPayment,
			userIdQuery,
		],
	);

	useEffect(() => {
		formik.setValues(initialValues);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues]);

	const onSubmit = (values: TelegramUserSettingsInput) =>
		updateSettings({
			variables: {
				input: {
					...values,
				},
			},
		})
			.then(() => {
				enqueueSnackbar('Настройки успешно сохранены', {variant: 'success'});
			})
			.catch((error) => {
				enqueueSnackbar(error.message, {variant: 'error'});
			});

	const formik = useFormik({
		initialValues,
		onSubmit,
	});

	const modeInput = useMemo(() => {
		switch (modeEdit) {
			case true:
				return {
					label: 'Откл. режим редактирования',
					fn: () => {
						setModeEdit(false);
					},
				};

			case false:
				return {
					label: 'Вкл. режим редактирования',
					fn: () => setModeEdit(true),
				};
		}
	}, [modeEdit]);

	const formatDate = 'dd.MM.yyyy';

	const infoAboutUser = useMemo(() => {
		if (!data) return undefined;

		const {username, firstActionAt, lastActionAt, isActive, isSubscriptionActive, autoPayment} =
			data.getUserData;

		return [
			{label: 'Активный', value: isActive ? 'Да' : 'Нет'},
			{label: 'Никнейм', value: username ?? 'Не указан'},
			{
				label: 'Впервые активен',
				value: format(firstActionAt, formatDate, {locale: ru}),
			},
			{
				label: 'Последняя активн.',
				value: format(lastActionAt, formatDate, {locale: ru}),
			},
			{
				label: 'Активная подписка',
				value: isSubscriptionActive ? 'Да' : 'Нет',
			},
			{
				label: 'Подписка действует до',
				value: (
					<SavedDateCalendar formik={formik} name={'activeToDate'} modeEdit={modeEdit} />
				),
			},
			{
				label: (
					<Tooltip content={'Автоплатеж можно только отключить'}>
						<Link>Автоплатеж</Link>
					</Tooltip>
				),
				value: (
					<SavedSwitch
						formik={formik}
						name={'autoPayment'}
						modeEdit={modeEdit}
						disabled={!autoPayment}
					/>
				),
			},
			{
				label: 'Автоброней',
				value: (
					<>
						{data?.getUserData.freeSupplyCount.toLocaleString()}
						<IconToken className={css.icon} />
					</>
				),
			},
		];
	}, [data, formik, modeEdit]);

	return (
		<ContentBlock error={!!error?.message} loading={loading}>
			<form>
				<Grid container direction={'column'} wrap={'nowrap'}>
					<Grid item className={css.title}>
						Информация о пользователе{' '}
						<b>
							{data?.getUserData.firstName} {data?.getUserData.lastName}
						</b>
					</Grid>

					{infoAboutUser?.map(({label, value}, i) => (
						<Grid
							item
							container
							alignItems={'center'}
							className={css.infoBlock}
							key={i}
						>
							<Grid item xs>
								{label}
							</Grid>
							<Grid item xs className={css.value}>
								{value}
							</Grid>
						</Grid>
					))}
				</Grid>
				<Grid item>
					<div onClick={modeInput.fn} className={css.mode}>
						{modeInput.label}
						<span className={css.progress}>
							{loadingSettings && <CircularProgress size={20} />}
						</span>
					</div>
				</Grid>
			</form>
		</ContentBlock>
	);
};

export default GeneralInformationAboutUser;
