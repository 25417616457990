import {useLazyQuery, useMutation} from '@apollo/client';

import {graphql} from '../gqlApp';

graphql(`
	fragment BROADCAST_BASE on BroadcastEntity {
		id
		status
		imagePath
		name
		text
		recipientCount
		errorCount
		successCount
		createdAt
		buttonUrl
		buttonText
	}
`);

const BROADCASTS_QUERY = graphql(`
	query Broadcasts($input: PaginationInput!) {
		broadcasts(input: $input) {
			total
			items {
				...BROADCAST_BASE
			}
		}
	}
`);

export const useBroadcastsQuery = () => useLazyQuery(BROADCASTS_QUERY);

const CREATE_BROADCAST_MUTATION = graphql(`
	mutation CreateBroadcast($input: CreateBroadcastInput!, $file: Upload) {
		createBroadcast(input: $input, file: $file) {
			...BROADCAST_BASE
		}
	}
`);

export const useCreateBroadcastMutation = () => useMutation(CREATE_BROADCAST_MUTATION);

const TEST_BROADCAST_MUTATION = graphql(`
	mutation TestBroadcast($input: TestBroadcastInput!) {
		testBroadcast(input: $input)
	}
`);

export const useTestBroadcastMutation = () => useMutation(TEST_BROADCAST_MUTATION);

const EDIT_BROADCAST_MUTATION = graphql(`
	mutation EditBroadcast($input: EditBroadcastInput!, $file: Upload) {
		editBroadcast(input: $input, file: $file) {
			...BROADCAST_BASE
		}
	}
`);

export const useEditBroadcastMutation = () => useMutation(EDIT_BROADCAST_MUTATION);

const REMOVE_BROADCAST_MUTATION = graphql(`
	mutation RemoveBroadcast($id: Float!) {
		removeBroadcast(id: $id) {
			...BROADCAST_BASE
		}
	}
`);

export const useRemoveBroadcastMutation = () => useMutation(REMOVE_BROADCAST_MUTATION);

const START_BROADCAST_MUTATION = graphql(`
	mutation StartBroadcast($id: Float!) {
		startBroadcast(id: $id)
	}
`);

export const useStartBroadcastMutation = () => useMutation(START_BROADCAST_MUTATION);
