import React, {useMemo, useState} from 'react';
import {format, subDays} from 'date-fns';
import {DateRange} from '@mui/lab/DateRangePicker';
import {
	ContentBlock,
	DateRangeTemplate,
	ITableRow,
	RubleCellFormat,
	SelectRangeDate,
	TableAdaptive,
	TablePagingPanel,
	Tooltip,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';
import {ITableColumn} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit/dist/components/common/ui/table/Table';
import ReplayIcon from '@mui/icons-material/Replay';
import {Grid, IconButton} from '@mui/material';

import {useGetUserPayments} from 'api/user_settings';
import StatusPayCellFormat from 'components/common/table/StatusPayCellFormat';
import TypeCellFormat from 'components/common/table/TypeCellFormat';
import {PaymentStatus} from '../../gqlApp/graphql';

const PaymentsUserTable: React.FC = () => {
	const [dateRangeValue, setDateRangeValue] = React.useState<DateRange<Date>>([
		subDays(new Date(), 30),
		new Date(),
	]);

	const [currentPage, setCurrentPage] = useState(1);

	const {data, loading, error, refetch} = useGetUserPayments({
		dateRange: {
			dateTo: dateRangeValue[1]?.getTime(),
			dateFrom: dateRangeValue[0]?.getTime(),
		},
		page: currentPage,
		search: undefined,
	});

	const onDateRangeChange = (newValue: DateRange<Date>) => {
		setDateRangeValue(newValue);
	};

	const limit = 20;

	const columns = useMemo<ITableColumn[]>(
		() => [
			{
				name: 'date',
				title: 'Дата',
				format: (value) => format(+new Date(value), 'dd.MM.yyyy'),
			},
			{
				name: 'type',
				title: 'Тип',
				format: (value) => <TypeCellFormat value={value as string} />,
			},
			{
				name: 'amount',
				title: 'Стоимость',
				format: (value) => <RubleCellFormat value={value as number} />,
			},
			{
				name: 'status',
				title: 'Статус',
				format: (value) => <StatusPayCellFormat value={value as PaymentStatus} />,
			},
			{
				name: 'autoPayment',
				title: 'Рекурентный',
			},
		],
		[],
	);

	const rows = useMemo<ITableRow[]>(
		() =>
			data?.getUserPayments.items.map(({createdAt, amount, type, status, autoPayment}) => ({
				date: createdAt,
				amount,
				type,
				status,
				autoPayment: autoPayment ? 'Да' : 'Нет',
			})) || [],
		[data?.getUserPayments],
	);

	return (
		<ContentBlock
			label={
				<Grid container alignItems={'center'}>
					<Grid item>История оплат</Grid>
					<Grid item>
						<Tooltip content={'Обновить историю'}>
							<IconButton onClick={() => refetch()}>
								<ReplayIcon />
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			}
			loading={loading}
			error={!!error}
			rightHeaderBlock={
				<SelectRangeDate
					onChange={onDateRangeChange}
					dateRangeTemplate={DateRangeTemplate.month}
				/>
			}
		>
			{/*<MultipleSearchPaymentStatus*/}
			{/*	setSearchValues={setSearchValue}*/}
			{/*	searchValues={searchValue}*/}
			{/*	resetPage={() => setCurrentPage(1)}*/}
			{/*/>*/}

			<TableAdaptive columns={columns} rows={rows} height={300} />

			<TablePagingPanel
				currentPage={currentPage}
				totalCount={data?.getUserPayments.total}
				onCurrentPageChange={setCurrentPage}
				maxPageCount={limit}
			/>
		</ContentBlock>
	);
};

export default PaymentsUserTable;
