import React from 'react';
import {Switch} from '@mui/material';

interface ISavedSwitch {
	formik: any;
	name: string;
	modeEdit: boolean;
	disabled?: boolean;
}

const SavedSwitch: React.FC<ISavedSwitch> = (props) => {
	const {formik, name, modeEdit, disabled} = props;

	return (
		<>
			Нет{' '}
			<Switch
				disabled={!modeEdit || disabled}
				name={name}
				onBlur={formik.handleSubmit}
				checked={formik.values[name] ?? false}
				onClick={formik.handleChange}
			/>
			Да
		</>
	);
};

export default SavedSwitch;
